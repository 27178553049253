(function(){
  'use strict';

  angular
  .module('app')
  .component('modalDraw', {
    templateUrl: 'app/components/loterias/modalCreateDraw.html',
    controller: ModalCreateDraw,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateDraw.$inject = ['toastr','loteriasService'];

  function ModalCreateDraw(toastr, $loteriasService) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;

    setTimeout(function(){
      vm.daysOptions = vm.resolve.daysOptions;
      vm.types = vm.resolve.types;
      vm.turno = vm.resolve.turno;
      vm.loteria = vm.resolve.loteria;
      vm.newDraw = {loteria_id: vm.loteria.id};
    },0);


    function save() {
      vm.send = true;

      _.forEach(vm.newDraw.daysSelected, function(data){
        vm.newDraw[data] = true;
      })

      $loteriasService.saveDraw(vm.newDraw)
      .then(function(res){
        var sorteo = res.data
        sorteo.daysSelected = [];
        toastr.info(res.message);

        if(sorteo.lun){
  			   sorteo.daysSelected.push('lun')
    		}
        if(sorteo.mar){
  			   sorteo.daysSelected.push('mar')
    		}
        if(sorteo.mie){
  			   sorteo.daysSelected.push('mie')
    		}
        if(sorteo.jue){
  			   sorteo.daysSelected.push('jue')
    		}
        if(sorteo.vie){
  			   sorteo.daysSelected.push('vie')
    		}
        if(sorteo.sab){
  			   sorteo.daysSelected.push('sab')
    		}
        if(sorteo.dom){
  			   sorteo.daysSelected.push('dom')
    		}


        vm.close({$value: res.data});
      }, function(err){
        toastr.error(err.data.message);
      })

    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
