(function(){
  'use strict';

  angular
  .module('app')
  .component('showMyLimits', {
    templateUrl: 'app/components/structure/showMyLimits.html',
    controller: ModalShowMyLimitsController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalShowMyLimitsController.$inject = ['toastr','structureService','$rootScope','_','$window'];

  function ModalShowMyLimitsController(toastr, $structureService, $rootScope,_, $window) {
    var vm = this;
    vm.cancel = cancel;

    vm.$onInit = function(){
      vm.limits = vm.resolve.limits;
      console.log(vm.limits)
    }
    // vm.requestIncreaseLimits = function(){
      // Swal({
      //   title: 'Indique el monto',
      //   input: 'text',
      //   inputAttributes: {
      //     autocapitalize: 'off'
      //   },
      //   showCancelButton: true,
      //   confirmButtonText: 'Look up',
      //   showLoaderOnConfirm: true,
      //   preConfirm: (login) => {
      //     return fetch(`//api.github.com/users/${login}`)
      //       .then(response => {
      //         if (!response.ok) {
      //           throw new Error(response.statusText)
      //         }
      //         return response.json()
      //       })
      //       .catch(error => {
      //         Swal.showValidationMessage(
      //           `Request failed: ${error}`
      //         )
      //       })
      //   },
      //   allowOutsideClick: () => !swal.isLoading()
      // }).then((result) => {
      //   if (result.value) {
      //     swal({
      //       title: `${result.value.login}'s avatar`,
      //       imageUrl: result.value.avatar_url
      //     })
      //   }
      // })
    // }

    vm.applyPorcentajes = function(currenLotery){

      _.map(currenLotery.sorteos, function(sorteo){
        if(vm.globalPercentTriple > 0){
          sorteo.limite_triple_calculado = (parseFloat(sorteo.limite_triple) * vm.globalPercentTriple) / 100 + parseFloat(sorteo.limite_triple);
        }
        if(vm.globalPercentTerminal > 0){
          sorteo.limite_terminal_calculado = (parseFloat(sorteo.limite_terminal) * vm.globalPercentTerminal) / 100 + parseFloat(sorteo.limite_terminal);
        }
      })
    }

    vm.requestIncreaseLimits = function(){
      vm.close();

    }



    function cancel() {
        vm.dismiss();
    }
  }
})();
