(function(){
  'use strict';
  angular
  .module('app')
  .component('reportsAmountTickets', {
    templateUrl: 'app/components/reports-amount-tickets/reports-amount-tickets.html',
    controller: ReportAmountTicketController
  });

  ReportAmountTicketController.$inject = ['toastr','$window','reportsService','structureService','miscelaneos','moment','user','$rootScope'];

  function ReportAmountTicketController(toastr, $window, $reportsService, $structureService, $miscelaneos, moment, user, $rootScope) {
    var vm = this;
    vm.loading = false;
    vm.getReport = getReport;
    vm.amount = 0;

    vm.$onInit = function(){

    }

    function getReport(){
      vm.loading = true;
      var data  = {
        monto: vm.amount,
        fecha: moment(vm.date).format('YYYY-MM-DD')
      }
      $reportsService.getReportsAmountTicket(data)
      .then(function(res){
        vm.loading = false;
        vm.resultReports = res;
      }, function(err){
        toastr(err.data.message);
        vm.loading = false;
      })
    }








    //Input date
    vm.today = function() {
      vm.date = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }


})();
