(function() {
  'use strict';
  angular
    .module('app')
    .factory('url', url);

  url.$inject = ['BASE_URL','DOMAIN_URL','OPERADORA_URL'];

  function url(BASE_URL,DOMAIN_URL,OPERADORA_URL) {
    return {
      getApiUrl: getApiUrl,
      getBaseUrl: getBaseUrl,
      getAuthUrl: getAuthUrl,
      getFrontendUrl: getFrontendUrl,
      getApiCentinelaUrl: getApiCentinelaUrl,
      getApiOperadoraUrl: getApiOperadoraUrl
    };

    function getApiUrl(resource) {
      return BASE_URL + '/api' + resource;
    }
    function getApiOperadoraUrl(resource) {
      return OPERADORA_URL + '/api' + resource;
    }

    function getApiCentinelaUrl(resource){
      return 'http://34.227.3.72:4004/api/'+resource;
    }

    function getAuthUrl(){
      return BASE_URL + '/login';
    }

    function getBaseUrl() {
      return BASE_URL;
    }
    function getFrontendUrl() {
      return DOMAIN_URL;
    }
  }
})();
