angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider,$httpProvider) {
  $httpProvider.interceptors.push(['$q', 'user', '$rootScope','$state','$window', function ($q, user, $rootScope,$state, $window) {
      return {
        request: function(config) {
          config.headers['content-type'] = 'application/json';
          if(user.getCurrentUser()){
            config.headers['structure'] = user.getCurrentUser().estructura_id;
            config.headers['token'] = user.getCurrentUser().token;
            if(user.getCurrentUser().technical){
              config.headers['technical'] = true;
            }
          }

          return config;
        },
        responseError: function(rejection) {
          if (rejection.status == 401) {
            $rootScope.$broadcast('UNAUTHORIZED');
          }

          return $q.reject(rejection);
        }
      };
    }]);
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('webLayout', {
      abstract: true,
      component: 'webLayout'
    })
    .state('loginLayout', {
      abstract: true,
      component: 'loginLayout'
    })
    .state('auth', {
      parent: 'loginLayout',
      url: '/login',
      component: 'auth'
    })
    .state('authTechnical', {
      parent: 'loginLayout',
      url: '/login-tecnicos',
      component: 'authTechnical'
    })
    .state('home', {
      parent: 'webLayout',
      url: '/',
      component: 'home',
      resolve: {
        currentUser: [
          '$q',
          'user',
          function ($q, user) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('loterias', {
      parent: 'webLayout',
      url: '/loterias',
      component: 'loterias',
      resolve: {
        currentUser: [
          '$q',
          'user',
          function ($q, user) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('structure', {
      parent: 'webLayout',
      url: '/estructuras',
      component: 'structure',
      resolve: {
        currentUser: [
          '$q',
          'user',
          function ($q, user) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('ticketConsult', {
      parent: 'webLayout',
      url: '/consulta-de-tickets',
      component: 'ticketConsult',
      resolve: {
        currentUser: [
          '$q',
          'user',
          function ($q, user) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('technicians', {
      parent: 'webLayout',
      url: '/tecnicos',
      component: 'technicians',
      resolve: {
        currentUser: [
          '$q',
          'user',
          '$state',
          function ($q, user, $state) {
            if (user.getCurrentUser()) {
              // if(!user.getCurrentUser().permisos.usuarios.active){
              //   $state.go('home');
              // }
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('installation', {
      parent: 'webLayout',
      url: '/asignacion-de-instalaciones',
      component: 'installation',
      resolve: {
        currentUser: [
          '$q',
          'user',
          '$state',
          function ($q, user, $state) {
            if (user.getCurrentUser()) {
              // if(!user.getCurrentUser().permisos.usuarios.active){
              //   $state.go('home');
              // }
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('reviewInstallation', {
      parent: 'webLayout',
      url: '/revision-de-instalaciones',
      component: 'reviewInstallation',
      resolve: {
        currentUser: [
          '$q',
          'user',
          '$state',
          function ($q, user, $state) {
            if (user.getCurrentUser()) {
              // if(!user.getCurrentUser().permisos.usuarios.active){
              //   $state.go('home');
              // }
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    })

    .state('reports_banca', {
      parent: 'webLayout',
      url: '/reporte-de-banca',
      component: 'reportsBanca',
      resolve: {
        currentUser: [
          '$q',
          'user',
          '$state',
          function ($q, user, $state) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    })
    .state('reports', {
      parent: 'webLayout',
      url: '/reportes',
      component: 'reports',
      resolve: {
        currentUser: [
          '$q',
          'user',
          '$state',
          function ($q, user, $state) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    }).state('reports_lotery', {
      parent: 'webLayout',
      url: '/reportes-por-loteria',
      component: 'reportsLotery',
      resolve: {
        currentUser: [
          '$q',
          'user',
          '$state',
          function ($q, user, $state) {
            if (user.getCurrentUser()) {
              return $q.resolve(user);
            }
            return $q.reject('UNAUTHORIZED');
          }
        ]
      }
    })
    .state('users', {
    parent: 'webLayout',
    url: '/usuarios',
    component: 'user',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reports_quintico', {
  parent: 'webLayout',
  url: '/reporte-de-ventas-quintico',
  component: 'reportsQuintico',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
}).state('coupons', {
  parent: 'webLayout',
  url: '/asignacion-de-cupones',
  component: 'coupons',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
}).state('delete_tickets', {
  parent: 'webLayout',
  url: '/borrar-tickets',
  component: 'deleteTickets',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
}).state('mobiles', {
  parent: 'webLayout',
  url: '/dispositivos-moviles-asociados',
  component: 'mobiles',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
}).state('accounts', {
  parent: 'webLayout',
  url: '/cuentas-para-pago-movil',
  component: 'accountsMovilPay',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
})
.state('reportsTicketAward', {
  parent: 'webLayout',
  url: '/consutal-tickets-premiados',
  component: 'reportsTicketAward',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
})
.state('numberReport', {
  parent: 'webLayout',
  url: '/reporte-de-numeros',
  component: 'numberReport',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
}).state('requestReleases', {
  parent: 'webLayout',
  url: '/solicitudes-de-liberacion',
  component: 'requestReleases',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
})
.state('reportsAmountTickets', {
  parent: 'webLayout',
  url: '/reporte-monto-de-tickets',
  component: 'reportsAmountTickets',
  resolve: {
    currentUser: [
      '$q',
      'user',
      '$state',
      function ($q, user, $state) {
        if (user.getCurrentUser()) {
          return $q.resolve(user);
        }
        return $q.reject('UNAUTHORIZED');
      }
    ],
  }
}).state('resultReportQuintico', {
    parent: 'webLayout',
    url: '/reporte-de-premiacion-quintico',
    component: 'reportResultQuintico',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  });


  }
