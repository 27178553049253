(function(){
  'use strict';

  angular
  .module('app')
  .component('adminMobile', {
    templateUrl: 'app/components/mobiles/admin-mobile.html',
    controller: ModalCreateCounpon,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateCounpon.$inject = ['toastr','mobilesService','_','$rootScope','user'];

  function ModalCreateCounpon(toastr, $mobilesService, _, $rootScope,$user) {
    var vm = this;
    vm.update = update;
    vm.send = false;
    vm.cancel = cancel;
    vm.prettyAmount = prettyAmount;
    vm.setPercent = setPercent;
    vm.activos = [{activo:false,description:'Inactivo',type:'danger',id: 0},{activo:true,description:'Activo',type:'success',id: 1}];
    vm.statuses = [
      {status:'E',description:'En Espera por aprobacion',type:'info',id:2},
      {status:'M',description:'Moroso',type:'warning',id:3},
      {status:'F',description:'Fraudulento',type:'danger',id:4},
      {status:'B',description:'Bloqueado',type:'danger',id:5},
      {status:'A',description:'Activo',type:'primary',id:6}
    ];

    vm.$onInit = function(){
      vm.mobile = vm.resolve.mobile;
      vm.mobile.limite_preaprobado_pretty = accounting.formatMoney(vm.mobile.limite_preaprobado, '','2','.',',');
    }

    function update() {
      vm.send = true;
      $mobilesService.updatedddMobile(vm.mobile.id, vm.mobile)
      .then(function(res){
        vm.send = false;
        toastr.success('Actualizado con exito');
        $rootScope.$emit('mobiles',{});
        vm.close({$value: res.data});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })
    }

    function prettyAmount(data){
      console.log(data)
      data.limite_preaprobado_pretty = accounting.formatMoney(data.limite_preaprobado, '','2','.',',');
    }
    function setPercent(data){
      var monto = (data.limite_preaprobado * data.porcentaje_preaprobado) / 100;
      data.amountPercentPretty = accounting.formatMoney(monto, '','2','.',',');
    }
    function cancel() {
        vm.dismiss();
    }
  }
})();
