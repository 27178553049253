(function(){
  'use strict';

  angular
  .module('app')
  .component('createTechnicians', {
    templateUrl: 'app/components/technicians/createTechnicians.html',
    controller: ModalCreateTechnician,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateTechnician.$inject = ['toastr','tecnicianService','_','$rootScope','user'];

  function ModalCreateTechnician(toastr, $tecnicianService, _, $rootScope,$user) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.user = {estructura_id: $user.getCurrentUser().estructura_id};
    vm.activeTap = 0;

    setTimeout(function(){
      console.log(vm.user)
    },0);



    function save() {
      vm.send = true;

      $tecnicianService.createTechnician(vm.user)
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
        vm.close({$value: res.data});
      }, function(err){
        toastr.error('Hubo un error');
      })
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
