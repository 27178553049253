angular
  .module('app')
  .filter('prettySing', prettySing);

function prettySing() {

  return function (signo) {

    var signos = [{	name: 'Aries',code: 1},{	name: 'Tauro',code: 2},{	name: 'Geminis',code: 3},{	name: 'Cancer',code: 4},{	name: 'Leo',code: 5},{	name: 'Virgo',code: 6},{	name: 'Libra',code: 7},{	name: 'Escorpio',code: 8},{	name: 'Sagitario',code: 9},{	name: 'Capricornio',code: 10},{	name: 'Acuario',code: 11},{	name: 'Piscis',code: 12}];
    if(signo > 0){
      return _.findWhere(signos, {code: signo}).name;
    }else{
      return '';
    }

  };
}
