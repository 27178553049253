(function(){
  'use strict';

  angular
  .module('app')
  .component('createUser', {
    templateUrl: 'app/components/users/createUser.html',
    controller: ModalCreateUser,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateUser.$inject = ['toastr','userService','_','$rootScope','user'];

  function ModalCreateUser(toastr, $userService, _, $rootScope, $user) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.checkAll = checkAll;
    vm.uncheckAll = uncheckAll;
    vm.send = false;
    vm.user = {estructura_id: $user.getCurrentUser().estructura_id};
    vm.activeTap = 0;

    setTimeout(function(){

      vm.user.permissions = _.reduce(vm.resolve.sections, function(memo, data){
        memo.push({
          active: true,
          section_id: data.id,
          name: data.name,
          pretty_name: data.pretty_name
        })

        return memo;
      }, [])
      console.log(vm.user)
    },0);



    function save() {
      vm.send = true;
      $userService.createUser(vm.user)
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
        vm.close({$value: res.data});
      }, function(err){
        toastr.error('Hubo un error');
      })
    }

    function checkAll(){
      _.forEach(vm.user.permissions, function(res){
        res.active = true;
      })
    }

    function uncheckAll(){
      _.forEach(vm.user.permissions, function(res){
        res.active = false;
      })
    }

    vm.next = function(action){
      if(action == 'next'){
        if(vm.activeTap == 0){
          vm.activeTap = 1;
        }
      }else{
        vm.activeTap = 0;
      }
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
