(function(){
  'use strict';

  angular
  .module('app')
  .component('ticketConsult', {
    templateUrl: 'app/components/ticket_consult/ticket_consult.html',
    controller: TicketConsultController
  });

  TicketConsultController.$inject = ['$uibModal','toastr','$rootScope','structureService','_','$window','ticketsService'];


  function TicketConsultController($uibModal,toastr, $rootScope, $structureService, _, $window,$ticketsService) {
    var vm = this;
    vm.setType = setType;
    vm.getTickets = getTickets;
    vm.getTicketDetail = getTicketDetail;
    vm.send = false;
    vm.type = 0;
    vm.status = 0;
    vm.status2 = 0;
    vm.sellers = [];
    vm.statuses = [{status:2,description:'En Espera',type:'default'},{status:1,description:'vendido',type: 'success'}, {status:3,description:'Reversado',type:'warning'},{status:4,description:'Eliminado',type:'danger'}];
    vm.statuses2 = [{status:1,description:'Premiado',type:'info'},{status:2,description:'Pagado',type: 'primary'}];

    vm.$onInit = function(){
      vm.send = true;
      $structureService.getSellers()
      .then(function(res){
        vm.send = false;
        vm.sellers = res;
      })
    }

    function getTickets(id){
      vm.send = true;

      var data = {
        id: id,
        type: vm.type,
        status: 0,
        date: moment(vm.date).format('DD/MM/YYYY'),
      }

      if(vm.type == 1){
        data.status = vm.status;
      }else{
        data.status = vm.status2;
      }

      $ticketsService.getTicketsFromSellers(data)
      .then(function(res){
        vm.send = false;
        if(res.length == 0) {
          toastr.info('No posee tickets vendido para la fecha: '+ data.date,'Vendedor');
        }else{
          vm.tickets = res;
        }
        console.log(res)
        if(res.length > 0){
          vm.totals = _.reduce(res, function(memo,data){
          memo.total_amount += parseFloat(data.ticket_amount);
          memo.total_premiado += parseFloat(data.premiados);
          return memo;
          }, {total_amount: 0, total_premiado: 0})
          console.log(vm.totals)
        }

      })
    }

    function setType(type){
      vm.type = type;
      vm.tickets = [];
      vm.detailTicket = undefined;
      vm.ticketSelected = undefined;
    }

    function getTicketDetail(ticket){
      var data = {
        id: ticket.id,
        tipo: vm.type,
      }
      if(ticket.esquintico){
        data.esquintico = eval(ticket.esquintico);
      }
      $ticketsService.getDetailTicket(data)
      .then(function(res){
        vm.detailTicket = res;
        vm.detailTicket.ticket.replace(/\n|\r/g, '<br>');
      })
    }













    //Input date
    vm.today = function() {
      vm.date = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }





})();
