(function() {
  'use strict';
  angular
    .module('app')
    .run(run)
    .run(function(ActionCableConfig, BASE_URL){
      // ActionCableConfig.wsUri = "ws://34.227.3.72:4002/cable";
      ActionCableConfig.wsUri = "ws://"+BASE_URL.split('//')[1]+"/cable";
      ActionCableConfig.autoStart = true;
    });

  run.$inject = ['$rootScope', '$state', 'authService', '$document', '$transitions', 'user'];

  function run($rootScope, $state, $authService, $document, $transitions, $user) {
    $transitions.onSuccess({ }, function() {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });

    $transitions.onError({ }, function(trans) {
      switch (trans.error().detail) {
        case 'ALREADY_LOGGED_IN':
          $state.go('home');
          break;
        case 'UNAUTHORIZED':
          unauthorizedHandler();
          break;
        default:
          $state.go('404');
      }
    });

    $rootScope.$on('UNAUTHORIZED', unauthorizedHandler);

    function unauthorizedHandler() {
      if ($user.getCurrentUser()) {
        $authService.logout();
      }
      $state.go('auth');
    }
  }
})();
