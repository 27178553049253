(function () {
  'use strict';
  angular
  .module('app')
  .factory('reportsService', reportsService);

  reportsService.$inject = ['$http', 'url'];

  function reportsService($http, $url) {

    function hashToParams(params){
      var params_url = '';

      Object.keys(params).forEach(function(item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    function getReportTypeStructure(data){
      return $http.get($url.getApiUrl('/reportes/tipo_estructura'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function getReportSalesGroup(data){
      return $http.get($url.getApiUrl('/reportes/report_sales_grupo'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function getReportSalesPointSalesLotery(data){
      return $http.get($url.getApiUrl('/reportes/report_sales_point_loteria'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function getReportSalesPointSalesDraw(data){
      return $http.get($url.getApiUrl('/reportes/report_sales_point_sorteo'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function getReportSalesQuintico(data){
      return $http.post('http://administrative.tuquintico.com/public/api/reports/get_tickets_point_sales',data)
      // return $http.post('http://192.168.1.11:3000/public/api/reports/get_tickets_point_sales',data)
      .then(function(res){
        return res.data
      })
    }

    function getTicketAward(data){
      return $http.get($url.getApiUrl('/reportes/report_ticket_premiado'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function getNumbers(data){
      return $http.get($url.getApiUrl('/reportes/mas_vendidos'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function getReportsBanca(data){
      return $http.get($url.getApiUrl('/reportes/report_banca'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function getReportsAmountTicket(data){
      return $http.get($url.getApiUrl('/reportes/consulta_monto_vendido_ticket'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function getReportResultQuintico(data){
      return $http.get($url.getApiUrl('/reportes/consulta_resultados_quintico'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    return {
      getReportTypeStructure: getReportTypeStructure,
      getReportSalesGroup: getReportSalesGroup,
      getReportSalesPointSalesLotery: getReportSalesPointSalesLotery,
      getReportSalesPointSalesDraw: getReportSalesPointSalesDraw,
      getReportSalesQuintico: getReportSalesQuintico,
      getTicketAward: getTicketAward,
      getNumbers: getNumbers,
      getReportsBanca: getReportsBanca,
      getReportsAmountTicket: getReportsAmountTicket,
      getReportResultQuintico: getReportResultQuintico
    };
  }
})();
