(function(){
  'use strict';

  angular
  .module('app')
  .component('structure', {
    templateUrl: 'app/components/structure/structure.html',
    controller: StructureController
  });

  StructureController.$inject = ['$uibModal','toastr','$rootScope','miscelaneos','_','$window','structureService','loteriasService','$timeout','authService','user'];


  function StructureController($uibModal,toastr, $rootScope, $miscelaneos, _, $window, $structureService, $loteriasService, $timeout, $authService, $user) {
    var vm = this;
    vm.send = false;
    vm.loadingData = false;
    vm.inactiveCreate = true;
    vm.notCurrentStructure = true;
    vm.hijos = [];
    vm.groups = [];
    vm.structure = {};
    vm.lotteries = [];
    vm.structures = [];
    vm.settingsConfig = [];
    vm.type_structures = [];
    vm.structureInRecursivity = [];
    vm.freeUser = freeUser;
    vm.createUser = createUser;
    vm.destroyUser = destroyUser;
    vm.showMyLimits = showMyLimits;
    vm.useLimitsFather = useLimitsFather;
    vm.createStructure = createStructure;
    vm.calculateAmount = calculateAmount;
    vm.calculatePercent = calculatePercent;
    vm.updateConfigPremio = updateConfigPremio;
    vm.checkPadreSelected = checkPadreSelected;
    vm.getStructureDetails = getStructureDetails;
    vm.calculateAmountTriple = calculateAmountTriple;
    vm.updateCurrentStructure = updateCurrentStructure;
    vm.updateConfigLoteriaEstructura = updateConfigLoteriaEstructura;
    var apple_selected, tree, treedata_avm, treedata_geography;


    function init() {
      vm.loadingData = true;

      $structureService.getStructures()
      .then(function(res){
        vm.doing_async = true;
        vm.structures = res;

        makeAbnTree(vm.structures)

        $structureService.getPadre()
        .then(function(res){
          vm.padre = res.estructura;
        })
      })



      $miscelaneos.getTypeStructures()
      .then(function(res){
        vm.type_structures = res;
      })

      $miscelaneos.getStates()
      .then(function(res){
        vm.states = res;
      })

      $miscelaneos.getSetting()
      .then(function(res){
        vm.structure.configuracion_estructura = res;
      })

      $miscelaneos.getLimites()
      .then(function(res){
        vm.structure.configuracion_limites = res;
      })


    }
    init();

    // function getPadre(arr){
    //   $structureService.getPadre()
    //   .then(function(res){
    //     vm.padre = res.estructura;
    //     _.forEach(arr.limites, function(data){
    //       _.forEach(data.sorteos, function(sorteo){
    //         sorteo.porcentajeReference = _.findWhere(vm.padre, {sorteo_id: data.id}).limite;
    //       })
    //     })
    //   })
    // }

    function makeAbnTree(arr){
      _.map(arr, function(data){
        if(data.padre_id == null){
           data.padre_id = 0;
         }
         data.label = data.id +'-> '+ data.nombre +' -- '+ data.tipo;
         data.onSelect = function(){vm.getStructureDetails(data)};
         if(data.hasOwnProperty('children')){
           makeAbnTree(data.children)
         }else{
           data.children = [];
         }
      })
      vm.loadingData = false;
      vm.inactiveCreate = false;
    }


    function createStructure() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createStructureComponent',
        windowClass: 'createStructureComponent',
        backdrop: false,
        size: 'lg',
        resolve: {
          type_structures: function(){
            return angular.copy(vm.type_structures)
          },
          structure: function() {
            return angular.copy(vm.structure)
          },
          structures: function(){
            return angular.copy(vm.groups)
          },
          padre: function(){
            return angular.copy(vm.padre)
          },
          states: function(){
            return vm.states;
          }
        }
      });

      modal.result.then(function(res){
        vm.structures.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    function showMyLimits(){

      var modal = $uibModal.open({
        animation: true,
        component: 'showMyLimits',
        backdrop: false,
        size: 'lg',
        resolve: {
          limits: function(){
            return $structureService.getMyLimits()
            .then(function(res){return res})
          }
        }
      });

      modal.result.then(function(res){
        $window.swal('Solicitud',' de limites realizada con exito','success');
      })
    }

    function createUser() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createUserComponent',
        windowClass: 'createLoteriasComponent',
        size: 'lg',
        resolve: {
          structure: function() {
            return angular.copy(vm.currentStructure)
          }
        }
      });

      modal.result.then(function(res){
        vm.currentStructure.user.push(res.data);
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    function updateCurrentStructure(tap, data){
      vm.send = true;
      switch (tap) {
        case 'firsTap':
          if(vm.estadoSelected){
            vm.currentStructure.estado = vm.estadoSelected.id;
            vm.currentStructure.municipio = vm.municipioSelected.id;
          }
          $structureService.updateStructure(vm.currentStructure.id, vm.currentStructure)
          .then(function(res){
            toastr.info(res.message);
            vm.send = false;
          })
          break;
        case 'secondTap':
          var copy_configuracion_estructura = angular.copy(vm.currentStructure.configuracion_estructura);
          var data = _.forEach(copy_configuracion_estructura, function(setting){
            delete setting.nombre;
          });
          $structureService.updateStructureConfiguration({id: vm.currentStructure.id, configuracion_estructura: data})
          .then(function(res){
            toastr.info(res.message);
            vm.send = false;
          })

          break;
        case 'thirdTap':

        $structureService.updateUser(data.id, data)
        .then(function(res){
          toastr.info(res.message);
          vm.send = false;
        })

          break;

          case 'fourTap':

          var limites = [];

          _.forEach(vm.currentStructure.limites, function(loteria){
            _.forEach(loteria.sorteos, function(sorteo){

              limites.push({
                sorteo_id: sorteo.id,
                porcentaje: sorteo.porcentaje,
                limite: sorteo.limite,
                estructura_id: vm.currentStructure.id,
                limite_triple: sorteo.limite_triple,
                porcentaje_triple: sorteo.porcentaje_triple
              })
            })
          })

          $structureService.updateLimiteStructure(limites)
          .then(function(res){
            toastr.info(res.message);
            vm.send = false;
          }, function(res){
            vm.send = false;
          })


          break;
        default:

      }

    }

    function destroyUser(data, index){

      $window.swal({
        title: 'Esta seguro de borrar?',
        text: "Una vez borrado no se podra recuperar el usuario",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar'
      }).then(function(result){
        if (result.value) {
          $authService.destroyUser(data.id)
          .then(function(res){
            vm.currentStructure.user.splice(index, 1);
            swal('Borrado!','el usuario fue borrado con exito','success');
          })
        }else{
          toastr.error('Accion cancelada');
        }
      })
    }

    function getStructureDetails(data) {
      vm.notCurrentStructure = false;
      vm.loadingCurrenStructure = true;
      $structureService.showStructure(data.id)
      .then(function(res){
        vm.currentStructure = undefined;
        vm.currentStructure = res;

        if(res.tipo_estructura_id == 3){
          var estado = _.findWhere(vm.states, {id: vm.currentStructure.estado});
          vm.estadoSelected = estado;
          if(vm.currentStructure.estado){
            vm.municipioSelected = _.findWhere(estado.municipios,{id: vm.currentStructure.municipio});
          }

          vm.config_loteria_estructura = angular.copy(vm.currentStructure.padre_config_loteria_estructura)
        }
        _.forEach(vm.currentStructure.limites, function(loteria){
          _.forEach(loteria.sorteos, function(sorteo){
            var padre = _.findWhere(vm.padre, {sorteo_id: sorteo.id});
            if(padre){
              sorteo.porcentajeReference = angular.copy(padre.limite);
              sorteo.porcentajeReferenceTriple = angular.copy(padre.limite_triple);
              calculateAmountTriple(sorteo);
              calculateAmount(sorteo);
            }
          })
        })
        vm.loadingCurrenStructure = false;
      })
    }

    function updateConfigPremio(){
      vm.send = true;
      $structureService.updateConfigPremio({config_premio: vm.currentStructure.config_premio, estructura_id: vm.currentStructure.id})
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
      })
    }

    function updateConfigLoteriaEstructura(config){
      if(parseFloat(config.monto_minimo_ticket) < parseFloat(vm.config_loteria_estructura.monto_minimo_ticket)){
        toastr.error('Monto Minimo del ticket no debe ser menor al establecido '+ vm.config_loteria_estructura.monto_minimo_ticket)
        return
      }
      if(parseFloat(config.monto_por_jugada) < parseFloat(vm.config_loteria_estructura.monto_por_jugada)){
        toastr.error('Monto por jugada no debe ser menor al establecido '+ vm.config_loteria_estructura.monto_por_jugada)
        return
      }

      vm.send = true;
      $structureService.updateConfigLoteriaEstructura(vm.currentStructure.id, {config_loteria_estructura: config})
      .then(function(res){
        toastr.info(res.message);
        vm.send = false;
      }, function(res){
        vm.send = false;
      })

    }

    function checkPadreSelected(){
      vm.currentStructure.padre_id = vm.father.id;
    }

    function calculatePercent(data){
      data.porcentaje = data.limite / data.porcentajeReference * 100;
      // data.limite = accounting.formatMoney(data.limite, '');
      setAmountPretty(data)
    }

    function calculateAmount(data){
      data.limite = data.porcentajeReference * data.porcentaje / 100;
      // data.limite = accounting.formatMoney(data.limite, '');
      setAmountPretty(data)
    }

    function setAmountPretty(data){
      data.pretty_amount = accounting.formatMoney(data.limite, 'Bs.S ','2','.',',');
    }

    function calculateAmountTriple(data){
      data.limite_triple = data.porcentajeReferenceTriple * data.porcentaje_triple / 100;
      setAmountPrettyTriple(data)
    }

    function setAmountPrettyTriple(data){
      data.pretty_amount_triple = accounting.formatMoney(data.limite_triple, 'Bs.S ','2','.',',')
    }

    function freeUser(user){

      $window.swal({
        title: 'Esta seguro de Liberar?',
        text: "Una vez liberada la taquilla podra ser instalada en otra computadra",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Liberar'
      }).then(function(result){
        if (result.value) {
          $structureService.freeUser({
            usuario_id: user.id,
            estructura_id: user.estructura_id,
            locker_id: user.locker_id
          })
          .then(function(res){
            swal('exito',res.message,'success');
          },function (err){
            swal('Error',err.data.message,'error');
          })
        }else{
          toastr.error('Accion cancelada');
        }
      })
    }

    function useLimitsFather(){
      _.forEach(vm.currentStructure.limites, function(loteria){
        _.forEach(loteria.sorteos, function(limite){
          limite.limite = 0;
          limite.limite_triple = 0;
          limite.porcentaje = 0;
          limite.porcentaje_triple = 0;
        })
      })

      $window.swal({
        title: '¿Esta seguro?',
        text: "Se colocaran todos los limites de la estructura en 0 para usar los del padre",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then(function(result){
        if (result.value) {
          vm.updateCurrentStructure('fourTap');
        }else{
          toastr.error('Accion cancelada');
        }
      })


    }

    $rootScope.$emit('webLayout', {data: {title: 'Estructuras',subtitle: 'Administracion de estructuras',previewPage: 'Dashboard', currentPage:'Estructura'}});

    $rootScope.$on('structures', function(){
      vm.hijos = [];
      init();
    })

  }

})();
