(function(){
  'use strict';

  angular
  .module('app')
  .component('createCoupons', {
    templateUrl: 'app/components/coupons/modalCoupon.html',
    controller: ModalCreateCounpon,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateCounpon.$inject = ['toastr','couponsService','_','$rootScope','user'];

  function ModalCreateCounpon(toastr, $couponsService, _, $rootScope,$user) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.cancel = cancel;
    vm.clearGrupo = clearGrupo;
    vm.prettyMount = prettyMount;
    vm.clearPointSale = clearPointSale;
    vm.user = $user.getCurrentUser();
    vm.$onInit = function(){
      vm.structures = vm.resolve.structures;
    }

    function save() {
      vm.send = true;
      var data = {
        referencia: vm.coupon.referencia,
        monto: vm.coupon.monto,
        activo: true,
        status: 'A',
        estructura_id: vm.user.estructura.id,
        usuario_id: vm.user.id
      }

      $couponsService.save(data)
      .then(function(res){
        vm.send = false;
        toastr.success('Cupon creado con exito');
        $rootScope.$emit('coupons',{});
        vm.close({$value: res.data});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })
    }

    function prettyMount(data){
      vm.pretty_amount = accounting.formatMoney(vm.coupon.monto, ' ','2','.',',');
    }

    function clearPointSale(){
      vm.point_sale = undefined;
    }
    function clearGrupo(){
      vm.structure = undefined;
    }
    function cancel() {
        vm.dismiss();
    }
  }
})();
