(function(){
  'use strict';

  angular
  .module('app')
  .component('ticketViewer', {
    templateUrl: 'app/components/miscelaneos/ticket-viewer.html',
    controller: TicketViewerController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  TicketViewerController.$inject = [];

  function TicketViewerController() {
    var vm = this;

    vm.$onInit = function(){
      vm.ticket = vm.resolve.ticket;
    }

  }
})();
