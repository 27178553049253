(function () {
  'use strict';
  angular
  .module('app')
  .factory('couponsService', couponsService);

  couponsService.$inject = ['$http', 'url'];

  function couponsService($http, $url) {

    function index(filters){
      return $http.get($url.getApiUrl('/cupones?filters='+filters.filters))
      .then(function(res){
        return res.data;
      })
    }

    function save(data){
      return $http.post($url.getApiUrl('/cupones'), data)
      .then(function(res){
        return res.data;
      })
    }
    function update(id, data){
      return $http.put($url.getApiUrl('/cupones/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function show(id){
      return $http.get($url.getApiUrl('/cupones/'+id))
      .then(function(res){
        return res.data;
      })
    }

    function destroy(id){
      return $http.delete($url.getApiUrl('/cupones/'+id))
      .then(function(res){
        return res.data;
      })
    }

    return {
      index: index,
      save: save,
      update: update,
      show: show,
      destroy:destroy
    };
  }
})();
