(function(){
  'use strict';

  angular
    .module('app')
    .component('reviewInstallation', {
      templateUrl: 'app/components/installation/review_installation.html',
      controller: ReviewInstallationController
    });

  ReviewInstallationController.$inject = ['tecnicianService','installationService','moment','$window','$rootScope','user','toastr','structureService'];

  function ReviewInstallationController($tecnicianService, $installationService, moment, $window, $rootScope, $user, toastr, $structureService) {
    var vm = this;
    vm.technicians = [];
    vm.installations = [];
    vm.doPrint = doPrint;
    vm.getInstalations = getInstalations;
    vm.requestRelease = requestRelease;
    vm.save = save;
    vm.send = false;
    vm.user = $user.getCurrentUser();
    vm.assingData = assingData;



    vm.$onInit = function(){
      vm.send = true;
      $tecnicianService.getTechnicians()
      .then(function(res){
        vm.send = false;
        if(vm.user.technical){
          vm.technicalSelected = _.findWhere(res, {id: vm.user.id});
        }else{
          vm.technicians = res;
        }
      })
      $installationService.getPointSales()
      .then(function(res){
        vm.point_sales = res;
      })
    }


    function getInstalations(){
      vm.send = true;
      var data = {
        technical: vm.technicalSelected.id,
        date_from: moment(vm.date_from).format('YYYY-MM-DD'),
        date_to: moment(vm.date_to).format('YYYY-MM-DD'),
      }
      $installationService.getByTechInstallations(data)
      .then(function(res){
        vm.send = false;
        vm.installations = res;
      }, function(err){vm.send = false;});
    }

    function doPrint(){
      $window.print();
    }

    function requestRelease(){
      if(vm.estationSelected){
        $window.swal({
          title: '¿Esta seguro?',
          text: "",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Liberar'
        }).then(function(result){
          if (result.value) {
            $structureService.createReleaseRequest({estation: vm.estationSelected.id})
            .then(function(res){
              toastr.success(res.message);
              vm.pointSaleSelected = {};
              vm.estationSelected = {};
            }, function(err){
              toastr.error(err.data.message);
              vm.pointSaleSelected = {};
              vm.estationSelected = {};
            })
          }
        })
      }else{
        toastr.warning('Seleccione una estacion para realizar la solicitud');
      }

    }

    function save(){
      vm.send = true;
      var data = {
        id: vm.estation_selected.id,
        salto_ticket: vm.estation_selected.config.salto_ticket,
        mensaje_ticket: vm.estation_selected.config.mensaje_ticket
      }
      if(vm.estation_selected.config.id){
        $installationService.updateSettings(vm.estation_selected.id, data)
        .then(function(res){
          vm.send = false;
          toastr.info('Guardado con exito');
        }, function(err){
          vm.send = false;
          toastr.error('Hubo un error')
        });
      }else{
        $installationService.saveSettigs(data)
        .then(function(res){
          vm.send = false;
          toastr.info('Guardado con exito');
        }, function(err){
          vm.send = false;
          toastr.error('Hubo un error')
        });
      }
    }

    function assingData(instalacion){
      vm.send = true;
      var data = {
        id: instalacion.usuario_id,
        salto_ticket: instalacion.salto_ticket,
        mensaje_ticket: instalacion.mensaje_ticket
      }
      $installationService.saveSettigs(data)
      .then(function(res){
        toastr.info('Guardado con exito');
        vm.send = false;
      }, function(err){
        vm.send = false;
        toastr.error('Hubo un error')
      });
    }










    //Input date
    vm.today = function() {
      vm.date = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open = function() {
      vm.popup.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false
    };

    vm.popup = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }

})();
