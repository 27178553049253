(function(){
  'use strict';

  angular
    .module('app')
    .component('mobiles', {
      templateUrl: 'app/components/mobiles/mobiles.html',
      controller: MobilesController
    });

    MobilesController.$inject = ['mobilesService','$rootScope','$uibModal', 'toastr'];

  function MobilesController($mobilesService, $rootScope, $uibModal, toastr) {
    var vm = this;
    vm.mobiles = [];
    vm.admin = admin;

    vm.$onInit = function(){
      $mobilesService.getMobiles()
      .then(function(res){
        vm.mobiles = res;
      })
    }

    function admin(mobile) {
      var modal = $uibModal.open({
        animation: true,
        component: 'adminMobile',
        size: 'lg',
        resolve: {
          mobile: function(){
            return angular.copy(mobile)
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    // function destroy(user, index) {
    //   swal({
    //     title: 'Confirmar borrado',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Borrar',
    //     showLoaderOnConfirm: true,
    //     preConfirm: function() {
    //       return new Promise(function(resolve, reject) {
    //         $mobilesService.deleteUser(user.id)
    //         .then(function() {
    //           vm.mobiles.splice(index, 1)
    //           resolve();
    //         }, function() {
    //           reject('Ha ocurrido un error');
    //         });
    //       });
    //     },
    //     allowOutsideClick: false,
    //   }).then(function() {
    //     swal(
    //       'Borrado!',
    //       'Ha sido borrado con éxito.',
    //       'success'
    //     );
    //   });
    // }



    $rootScope.$on('mobiles', function(event, data){
      vm.$onInit();
    })



    $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

  }

})();
