(function(){
  'use strict';
  angular
  .module('app')
  .component('numberReport', {
    templateUrl: 'app/components/number-report/number-report.html',
    controller: NumberReportController
  });

  NumberReportController.$inject = ['reportsService', 'structureService','user'];

  function NumberReportController($reportsService, $structureService, $user) {
    var vm = this;
    vm.send = false;
    vm.date = new Date();
    vm.resultReports = false;
    vm.types = [{tipo:1,description:'Triple'},{tipo:2,description:'Terminal'}];
    vm.filter = vm.types[0].tipo;

    vm.$onInit = function(){
      vm.structuras = [];
      vm.structuras.push({id: $user.getCurrentUser().estructura.id, nombre: 'Ver todos'});
      vm.resultReports = false;
      $structureService.getStructuresChildrens()
      .then(function(res){
        vm.structuras = vm.structuras.concat(res);
        vm.estructuraSelected = vm.structuras[0];
      })
      $structureService.getMyLimits()
      .then(function(res){
        vm.myproducts = res;
        vm.sorteos = _.reduce(res, function(memo, data){
          var sorteos = _.map(data.sorteos, function(sort){sort.nombre_producto = data.nombre; return sort})
          memo = memo.concat(sorteos)
          return memo;
        },[]);
      })
    }

    vm.getNumbers = function(){
      console.log(vm.filter)
      var data = {
        estructura_id: vm.estructuraSelected.id,
        tipo: vm.filter,
        sorteo_id:vm.prodctSelected.sorteo_id
      }
      $reportsService.getNumbers(data)
      .then(function(res){
        vm.reportNumbers = res;
        console.log(vm.reportNumbers.datos)
      })
    }

    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }


})();
