(function () {
  'use strict'
  angular
  .module('app')
  .factory('loteriasService', loteriasService);

  loteriasService.$inject = ['$http', 'url'];

  function loteriasService($http, $url) {

    function getLoterias(){
      return $http.get($url.getApiUrl('/loterias'))
      .then(function(res){
        return res.data;
      })
    }
    function saveLoteria(data){
      return $http.post($url.getApiUrl('/loterias'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateLoteria(id, data){
      return $http.put($url.getApiUrl('/loterias/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function saveDraw(data){
      return $http.post($url.getApiUrl('/sorteos'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateDraw(id, data){
      return $http.put($url.getApiUrl('/sorteos/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    return {
      getLoterias: getLoterias,
      saveLoteria: saveLoteria,
      updateLoteria: updateLoteria,
      saveDraw: saveDraw,
      updateDraw: updateDraw
    };
  }
})();
