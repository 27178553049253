(function(){
  'use strict';

  angular
  .module('app')
  .component('loading', {
    templateUrl: 'app/components/miscelaneos/loading.html',
    controller: loadingController,
    bindings:{
      message: "<"
    }
  });

  loadingController.$inject = [];

  function loadingController() {
    var vm = this;


  }

})();
