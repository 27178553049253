(function () {
  'use strict';

  angular
  .module('app')
  .factory('mobilesService', mobilesService);

  mobilesService.$inject = ['$http', 'url'];

  function mobilesService($http, $url) {

    function getMobiles(){
      return $http.get($url.getApiUrl('/movil'))
      .then(function(res){
        return res.data;
      })
    }

    function createdddMobile(data){
      return $http.post($url.getApiUrl('/movil'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updatedddMobile(id, data){
      return $http.put($url.getApiUrl('/movil/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function deletedddMobile(id){
      return $http.delete($url.getApiUrl('/movil/'+id))
      .then(function(res){
        return res.data;
      })
    }

    return {
      getMobiles: getMobiles,
      createdddMobile: createdddMobile,
      deletedddMobile: deletedddMobile,
      updatedddMobile: updatedddMobile
    };
  }
})();
