(function(){
  'use strict';

  angular
  .module('app')
  .component('authTechnical', {
    templateUrl: 'app/components/auth/auth.html',
    controller: AuthTechnicalController
  });

  AuthTechnicalController.$inject = ['authService','$window', '$state','user','toastr'];

  function AuthTechnicalController($authService, $window, $state, $user,toastr) {
    var vm = this;
    vm.title = 'Soporte Tecnico';
    vm.doLogin = doLogin;
    vm.login = angular.fromJson($window.localStorage.getItem('login'));


    function doLogin() {
      if(vm.login.remember){
          $window.localStorage.setItem('login', angular.toJson(vm.login));
      }else{
        $window.localStorage.removeItem('login');
      }
      vm.login.technical = true;

      $authService.doLogin(vm.login)
      .then(function(res){
        var user = res.user;
        user.technical = true;
        $user.setCurrentUser(user);
        $state.go('reviewInstallation');
        toastr.success('Inicio de session exitoso')
      }, function(err){
        toastr.error(err.data.message);
        vm.login = {};
      })
    }

  }
})();
