(function(){
  'use strict';
  angular
  .module('app')
  .component('webHeader', {
    templateUrl: 'app/components/web-header/web-header.html',
    controller: WebHeaderController
  });

  WebHeaderController.$inject = ['authService','ActionCableChannel','$state','miscelaneos','$timeout','user','ngAudio'];

  function WebHeaderController($authService,ActionCableChannel, $state, miscelaneos, $timeout, $user,ngAudio) {
    var vm = this;
    vm.doLogout = doLogout;

    var channel = new ActionCableChannel("NotificationsChannel", {});
    var callback = function(message){
      vm.notifications.push(message);
      vm.notificationSound.play();
      resetNotificationesCount();
    };
    channel.subscribe(callback)
    .then(function(){
      console.log('conectado al canal: NotificationsChannel')
      vm.sendToMyChannel = function(message){
        channel.send(moment().format('YYYY-MM-DD'), 'obtener_ultimos_cierres');
      };
      vm.goToNotification = function(item, index){
        item.vista = true;
        channel.send(item.id, 'update_notification_view')
        $timeout(function(){
          resetNotificationesCount()
          window.location = item.url;
        },0)
      }

      vm.toggleStatus = function(item){
        item.vista = !item.vista
        channel.send(item.id, 'toggle_notification_status');
        resetNotificationesCount();
      }
    });

    vm.$onInit = function(){
      vm.notificationSound = ngAudio.load("audios/notificacion.mp3");
      vm.currentUser = $user.getCurrentUser();
      miscelaneos.getLastNotifications()
      .then(function(res){
        vm.notifications = res;
        resetNotificationesCount()
      })
    }

    function doLogout() {
      $authService.logout();
    }



    function resetNotificationesCount(){
      vm.newNotifications = 0;
      _.forEach(vm.notifications, function(data){
        if(!data.vista){
          vm.newNotifications++
        }
      })
    }


  }

})();
