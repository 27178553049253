(function () {
  'use strict';
  angular
  .module('app')
  .factory('structureService', structureService);

  structureService.$inject = ['$http', 'url'];

  function structureService($http, $url) {

    function hashToParams(params){
      var params_url = '';

      Object.keys(params).forEach(function(item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    function getStructures(){
      return $http.get($url.getApiUrl('/estructura'))
      .then(function(res){
        return res.data;
      })
    }

    function getStructuresChildrens(){
      return $http.get($url.getApiUrl('/miscelaneos/get_structures_childrens'))
      .then(function(res){
        return res.data
      })
    }
    function saveStructure(data){
      return $http.post($url.getApiUrl('/estructura'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updateStructure(id, data){
      return $http.put($url.getApiUrl('/estructura/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function showStructure(id){
      return $http.get($url.getApiUrl('/estructura/'+id))
      .then(function(res){
        return res.data;
      })
    }

    function updateStructureConfiguration(data){
      return $http.post($url.getApiUrl('/estructura/update_configuracion'),data)
      .then(function(res){
        return res.data;
      })
    }

    function updateLimiteStructure(data){
      return $http.post($url.getApiUrl('/estructura/update_limites'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateUser(id, data){
      return $http.put($url.getApiUrl('/usuarios/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function getPadre(){
      return $http.get($url.getApiUrl('/miscelaneos/get_padre'))
      .then(function(res){
        return res.data;
      })
    }

    function getSellers(){
      return $http.get($url.getApiUrl('/estructura/obtener_vendedores'))
      .then(function(res){
        return res.data;
      })
    }

    function freeUser(data){
      return $http.post($url.getApiUrl('/estructura/liberar_usuario'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateConfigPremio(data){
      return $http.post($url.getApiUrl('/estructura/update_config_premio'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getMyLimits(data){
      return $http.get($url.getApiUrl('/estructura/get_limites_estructura'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getReleaseRequest(){
      return $http.get($url.getApiUrl('/release_requests'))
      .then(function(res){
        return res.data;
      })
    }
    function createReleaseRequest(data){
      return $http.post($url.getApiUrl('/release_requests'), data)
      .then(function(res){
        return res.data;
      })
    }
    function approveRelease(data){
      return $http.get($url.getApiUrl('/release_requests/approve_release'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function rejectRelease(data){
      return $http.get($url.getApiUrl('/release_requests/reject_release'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }
    function updateConfigLoteriaEstructura(id, data){
    return $http.post($url.getApiUrl('/estructura/update_config_loteria_estructura?estructura_id='+id), data)
    .then(function(res){
      return res.data;
    })
  }






    return {
      getStructures: getStructures,
      saveStructure: saveStructure,
      updateStructure: updateStructure,
      updateStructureConfiguration: updateStructureConfiguration,
      showStructure: showStructure,
      updateUser: updateUser,
      updateLimiteStructure: updateLimiteStructure,
      getPadre: getPadre,
      getSellers: getSellers,
      freeUser: freeUser,
      getStructuresChildrens: getStructuresChildrens,
      updateConfigPremio: updateConfigPremio,
      getMyLimits: getMyLimits,
      getReleaseRequest: getReleaseRequest,
      approveRelease: approveRelease,
      rejectRelease: rejectRelease,
      createReleaseRequest: createReleaseRequest,
      updateConfigLoteriaEstructura: updateConfigLoteriaEstructura
    };
  }
})();
