(function(){
  'use strict';

  angular
  .module('app')
  .component('reportResultQuintico', {
    templateUrl: 'app/components/report-result-quintico/report-result-quintico.html',
    controller: reportResultController
  })

  reportResultController.$inject = ['$uibModal', 'toastr', '$rootScope','drawQuinticoService','reportsService','structureService'];


  function reportResultController($uibModal, toastr, $rootScope, $drawQuinticoService, $reportsService, $structureService) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      vm.loading = true;
      $structureService.getStructuresChildrens()
      .then(function(data){
        vm.structures = data;
      })
      $drawQuinticoService.getDraws()
      .then(function(res){
        vm.draws = res;
        vm.loading = false;
        vm.drawSelected = res[0];
      })
    }

    vm.search = function(){
      vm.loading = true;
      var data = {
        id: vm.drawQuinticoSelected.id,
        estructura_id: vm.structureSelected ? vm.structureSelected.id : null
      }
      $reportsService.getReportResultQuintico(data)
      .then(function(res){
        vm.loading = false;
        vm.resultPreAwardDraw = res;
        vm.totalticketsPremiados = _.reduce(res.datos, function(memo, data){
          memo += data.cantidad_boletos;
          return memo;
        },0)
        vm.ticketsVendidos = vm.resultPreAwardDraw.total_venta / vm.drawQuinticoSelected.monto_boleto;
      })

    }

  }

})();
