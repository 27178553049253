(function(){
  'use strict';

  angular
  .module('app')
  .component('webLayout', {
    templateUrl: 'app/components/layouts/web-layout.html',
    controller: WebLayoutController
  });

  function WebLayoutController() {

    this.$onInit = function(){
      
    }
  };

})();
