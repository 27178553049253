(function(){
  'use strict';
  angular
  .module('app')
  .component('reportsLotery', {
    templateUrl: 'app/components/reports-lotery/reports-lotery.html',
    controller: ReportLoteryController
  });

  ReportLoteryController.$inject = ['toastr','$window','reportsService','structureService','miscelaneos','moment','user','$rootScope'];

  function ReportLoteryController(toastr, $window, $reportsService, $structureService, $miscelaneos, moment, user,$rootScope) {
    var vm = this;
    vm.send = false;
    vm.getBack = getBack;
    vm.printReport = false;
    vm.clearGrupo = clearGrupo;
    vm.consultSales = consultSales;
    vm.user = user.getCurrentUser();
    vm.clearPointSale = clearPointSale;
    vm.showReportByDraws = showReportByDraws;
    vm.printReportLotery = printReportLotery;
    vm.step = {
      step1: true,
      step2: false,
    }
    vm.$onInit = function(){
      $structureService.getStructuresChildrens()
      .then(function(data){
        vm.structures = data;
      })
      consultSales('all');
    }

    function consultSales(type){
      vm.send = true;
      var data = {
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD')
      };
      if(type == 'all'){
        data.id = vm.user.estructura_id
      }
      if(vm.structure){
        data.id = vm.structure.id;
      }
      if(vm.point_sale){
        data.id = vm.point_sale.id;
      }

      if(!vm.structure && !vm.point_sale){
        data.id = vm.user.estructura_id
      }

      $reportsService.getReportSalesPointSalesLotery(data)
      .then(function(res){
        vm.resultReports = _.map(res, function(data){
          data.porcentaje_premio = (data.premio / data.venta) * 100;
          data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
          return data;
        });

        vm.total = _.reduce(vm.resultReports, function(memo,data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, '','2','.',',');
          memo.total_comision += parseFloat(data.comision);
          memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, '','2','.',',');
          memo.tota_premio += parseFloat(data.premio);
          memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, '','2','.',',');

          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, '','2','.',',');
          memo.total_utilidad_final += parseFloat(data.utilidad_final);
          memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, '','2','.',',');
          memo.total_participacion += parseFloat(data.participacion);
          memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

          return memo;
        },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
        vm.send = false;
      });
    }

    function clearPointSale(){
      vm.point_sale = undefined;
    }
    function clearGrupo(){
      vm.structure = undefined;
    }

    function showReportByDraws(lotery){
      vm.send = true;
      vm.step.step1 = false;
      vm.step.step2 = true;
      var data = {
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD'),
        loteria_id: lotery.loteria_id,
        id: vm.user.estructura_id
      };
      if(vm.structure){
        data.id = vm.structure.id;
      }
      if(vm.point_sale){
        data.id = vm.point_sale.id;
      }
      if(!vm.structure && !vm.point_sale){
        data.id = vm.user.estructura_id
      }

      $reportsService.getReportSalesPointSalesDraw(data)
      .then(function(res){
        vm.resultReportDraw = _.map(res, function(data){
          data.porcentaje_premio = (data.premio / data.venta) * 100;
          data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
          return data;
        });

        vm.totalDraw = _.reduce(vm.resultReportDraw, function(memo,data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, '','2','.',',');
          memo.total_comision += parseFloat(data.comision);
          memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, '','2','.',',');
          memo.tota_premio += parseFloat(data.premio);
          memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, '','2','.',',');
          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, '','2','.',',');
          memo.total_utilidad_final += parseFloat(data.utilidad_final);
          memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, '','2','.',',');
          memo.total_participacion += parseFloat(data.participacion);
          memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

          return memo;
        },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
        vm.send = false;
      });
    }

    function getBack(step){
      if(step == 'step1'){
        vm.step.step1 = true;
        vm.step.step2 = false;
        vm.resultReportDraw = undefined;
        vm.totalDraw = undefined;
        return
      }
    }

    function printReportLotery(typeReport){
      if(typeReport == 'lotery'){
        $rootScope.$emit('printReportLoteryPDF', {
          dataTable: vm.resultReports,
          dataTotal: vm.total, dataCabecera:{
            desde: moment(vm.from).format('MM/DD/YYYY'),
            hasta: moment(vm.to).format('MM/DD/YYYY'),
            estructura1: vm.structure || undefined,
            estructura2: vm.point_sale || undefined
          }
        })
      }else{
        $rootScope.$emit('printReportLoteryPDF', {
          dataTable: vm.resultReportDraw,
          dataTotal: vm.totalDraw, dataCabecera:{
            desde: moment(vm.from).format('MM/DD/YYYY'),
            hasta: moment(vm.to).format('MM/DD/YYYY'),
            estructura1: vm.structure || undefined,
            estructura2: vm.point_sale || undefined
          }
        })
      }
      vm.printReport = true;

    }

    $rootScope.$on('goBack',function(){
      vm.printReport = false;
    })














    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup.opened = true;
    };
    vm.open1 = function(){
      vm.popup.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }


})();
