(function(){
  'use strict';

  angular
  .module('app')
  .component('deleteTickets', {
    templateUrl: 'app/components/delete-tickets/delete-tickets.html',
    controller: DeleteTicketController
  });

  DeleteTicketController.$inject = ['$uibModal','toastr','$rootScope','structureService','_','$window','ticketsService'];


  function DeleteTicketController($uibModal,toastr, $rootScope, $structureService, _, $window,$ticketsService) {
    var vm = this;
    vm.getTicketDetail = getTicketDetail;
    vm.getTickets = getTickets;
    vm.deleteTicket = deleteTicket;
    vm.send = false;
    vm.sellers = [];


    vm.$onInit = function(){
      vm.send = true;
      $structureService.getSellers()
      .then(function(res){
        vm.send = false;
        vm.sellers = res;
      })
    }

    function getTickets(id){
      vm.send = true;
      var data = {
        id: id,
        type: 1,
        status: 1,
        date: moment().format('DD/MM/YYYY'),
      }

      $ticketsService.getTicketsFromSellers(data)
      .then(function(res){
        vm.send = false;
        if(res.length == 0) {
          toastr.warning('No posee tickets vendido para hoy');
          vm.tickets = undefined;
        }else{
          vm.tickets = res;
        }
      })
    }

    function getTicketDetail(ticket){
      vm.currentTicket = ticket.ticket_id;
      $ticketsService.getDetailTicket({id: ticket.id, tipo: 1})
      .then(function(res){
        vm.detailTicket = res;
        vm.detailTicket.ticket.replace(/\n|\r/g, '<br>');
      })
    }


    function deleteTicket(){
      vm.send = true;
      var data = {
        cod: vm.sellerSelected.id,
        ticket: vm.currentTicket
      }

      swal({
        title: '¿Confirma que desea borrar?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $ticketsService.deleteTickets(data)
            .then(function(res) {
              if(res.cmd == 'C30'){
                vm.currentTicket = undefined;
                vm.detailTicket = undefined;
                vm.getTickets(vm.sellerSelected.id);
                resolve(res);
              }else{
                vm.send = false;
                throw new Error(res.msg);

              }
            })
            .catch(function(err){
              swal.showValidationError(err);
              swal.hideLoading();
            });
          });
        }
      }).then(function(result) {
        if(result.value){
          swal('Borrado!',result.value.msg,'success');
        }
      });

    }



  }

})();
