(function(){
  'use strict';

  angular
    .module('app')
    .component('installation', {
      templateUrl: 'app/components/installation/installation.html',
      controller: InstallationController
    });

  InstallationController.$inject = ['tecnicianService','installationService','moment','$window','$rootScope','toastr'];

  function InstallationController($tecnicianService, $installationService, moment, $window, $rootScope, toastr) {
    var vm = this;
    vm.technicians = [];
    vm.installations = [];
    vm.goBack =goBack;
    vm.printOrder = printOrder;
    vm.printScreen =printScreen;
    vm.setInstallations = setInstallations;
    vm.selectAll = selectAll;
    vm.unSelectAll = unSelectAll;
    vm.removeInstallation = removeInstallation;
    vm.print = false;
    vm.date = moment().format('DD/MM/YYYY');
    vm.asignation = {
      technical: null,
      installations: []
    }
    vm.$onInit = function(){
      $tecnicianService.getTechnicians()
      .then(function(res){
        vm.technicians = res;
      })

      $installationService.getInstallations()
      .then(function(res){
        var group = _.groupBy(res, function(data){
          return data.codagen
        });

        vm.installations = _.reduce(group, function(memo, data){
           memo.push({agen: data[0].codagen, estaciones: data, address: data[0].estructura.direccion});
           return memo;
        },[]);

        _.map(vm.installations, function(data){
          data.selected = false;
        })
        console.log(vm.installations)

      })
    }

    function removeInstallation(installation){
      var exist = _.findWhere(vm.installations, {agen: installation.agen});
      if(exist.selected){
        exist.selected = false;
      }
    }

    function setInstallations(installation){

      var exist = _.findWhere(vm.installations, {agen: installation.agen});
      if(exist.selected){
        exist.selected = false;
      }else{
        exist.selected = true;
      }
    }

    function printOrder(){
      var selected = false;
      if(!vm.asignation.technical){
        toastr.warning('Antes seleccione un tecnico','Error');
        return;
      }

      selected = _.forEach(vm.instalations, function(data){
        if(data.selected){
          return true;
        }
      });
      // if(!selected){
      //   toastr.warning('Seleccione una estacion a instalar','Error');
      //   return;
      // }
        swal({
          title: 'Esta Seguro de realizar esta asginacion?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Asignar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              vm.asignation.installations = _.reduce(vm.installations, function(memo, data){
                if(data.selected){
                  _.forEach(data.estaciones, function(data){
                    memo.push(data.id);
                  })
                }

                return memo
              },[]);

              $installationService.assignInstallations(vm.asignation)
              .then(function(res){
                vm.print = true;
                $rootScope.$emit('instalations',{});
                var group = _.groupBy(res.data, function(g){
                  return g.estructura.nombre
                });
                vm.instalacionList = _.reduce(group, function(memo, data){
                  memo.push({
                    estaciones: data,
                    estructura: data[0].estructura
                  });
                  return memo;
                },[])


                resolve();
              }, function(err){
                reject('Hubo un error');
              })


            });
          },
          allowOutsideClick: false,
        }).then(function(res) {});
    }

    function selectAll(){
      _.forEach(vm.installations, function(installation){
        installation.selected = true;
      })
    }

    function unSelectAll(){
      _.forEach(vm.installations, function(installation){
        installation.selected = false;
      })
    }

    function printScreen(){
      $window.print();
    }

    function goBack(){
      vm.print = false;
      vm.instalacionList = [];
    }
    $rootScope.$on('instalations', function(e,d){
      vm.$onInit();
    })

  }

})();
