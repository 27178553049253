(function(){
  'use strict';
  angular
    .module('app')
    .component('coupons', {
      templateUrl: 'app/components/coupons/coupons.html',
      controller: CouponsController
    });

  CouponsController.$inject = ['couponsService','$rootScope','$uibModal', 'toastr','structureService','user'];

  function CouponsController($couponsService, $rootScope, $uibModal, toastr, $structureService,$user) {
    var vm = this;
    vm.create = create;
    vm.aproveCoupon = aproveCoupon;
    vm.deleteCoupon = deleteCoupon;
    vm.viewHistory = viewHistory;
    vm.user = $user.getCurrentUser();

    vm.$onInit = function(){
      var filters = [];
      if(vm.user.permisos.use_coupon.active){
        filters.push('R');
      }
      if(vm.user.permisos.wait_coupon.active){
        filters.push('E');
      }
      if(vm.user.permisos.active_coupon.active){
        filters.push('A');
      }
      if(vm.user.permisos.completed_coupon.active){
        filters.push('C');
      }
      $couponsService.index({filters: filters.join('')})
      .then(function(res){
        vm.coupons = _.map(res, function(coupon){
          if(coupon.status == 'E'){
            coupon.status_pretty = 'En Espera por aprobacion';
          }
          if(coupon.status == 'A'){
            coupon.status_pretty = 'Activo';
          }
          if(coupon.status == 'C'){
            coupon.status_pretty = 'Completado';
          }
          if(coupon.status == 'R'){
            coupon.status_pretty = 'En Uso';
          }
          return coupon;
        });
      })
      $structureService.getStructuresChildrens()
      .then(function(res){
        vm.structures = res;
      })
    }


    function create(){
      var modal = $uibModal.open({
        animation: true,
        component: 'createCoupons',
        size: 'lg',
        resolve: {
          structures: function(){
            return vm.structures;
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function viewHistory(cupon){
      var modal = $uibModal.open({
        animation: true,
        component: 'viewUsed',
        size: 'lg',
        resolve: {
          coupon: function(){
            return cupon;
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function aproveCoupon(coupon){
      coupon.send = true;
      var updateCoupon = angular.copy(coupon)
      updateCoupon.status = 'A'
      swal({
        title: 'Confirmar Aprobacion',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aprovar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $couponsService.update(updateCoupon.id,updateCoupon)
            .then(function() {
              coupon.send = false;
              vm.$onInit();
              toastr.success('Aprobado con exito');
              resolve();
            }, function() {
              coupon.false
              vm.$onInit();
              toastr.error(err.data.message);
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      })

    }

    function deleteCoupon(coupon){
      coupon.send = true;
      swal({
        title: 'Confirme eliminado',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aprovar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $couponsService.destroy(coupon.id)
            .then(function() {
              vm.$onInit();
              toastr.success('Aprobado con exito');
              resolve();
            }, function() {
              vm.$onInit();
              toastr.error(err.data.message);
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      })

    }

    vm.filterCoupon = function(filter){
      if(filter == 'Todos'){
        vm.search = '';
      }else{
        vm.search = filter;
      }
    }

    $rootScope.$on('coupons', function(evt, value){
      vm.$onInit();
    })
  }
})();
