(function(){
  'use strict';
  angular
  .module('app')
  .component('reportsTicketAward', {
    templateUrl: 'app/components/report-ticket-award/report-ticket-award.html',
    controller: ReportTicketAwardController
  });

  ReportTicketAwardController.$inject = ['reportsService', 'structureService', 'ticketsService','$uibModal'];

  function ReportTicketAwardController($reportsService, $structureService, $ticketsService, $uibModal) {
    var vm = this;
    vm.send = false;
    vm.date = new Date();
    vm.getReportTicketAward = getReportTicketAward;
    vm.getTicketDetail = getTicketDetail;
    vm.resultReports = false;
    vm.statuses2 = [{status:'premiados',description:'Premiados',type:'info'},{status:'pagados',description:'Pagados',type: 'primary'},{status:'prem/pag',description:'Premiados/Pagados',type: 'primary'}];
    vm.filter = vm.statuses2[0].status

    vm.$onInit = function(){
      vm.resultReports = true;

      var data = {
        fecha: moment(vm.date).format('DD/MM/YYYY'),
        filter: angular.copy(vm.filter),
      }
      $reportsService.getTicketAward(data)
      .then(function(res){
        vm.dataReport = _.reduce(res, function(memo, data){
          switch (vm.filter) {
            case 'premiados':
              if(data.premiados > 0){
                memo.push(data);
              }
              break;
          case 'pagados':
            if(data.pagados > 0){
              memo.push(data);
            }
            break;
          case 'prem/pag':
            if(data.pagados > 0 || data.pagados > 0){
              memo.push(data);
            }
          break;
          }
          return memo;
        },[]);
        vm.resultReports = false;
      })
      $structureService.getSellers()
      .then(function(res){
        vm.send = false;
        vm.sellers = res;
      })

    }


    function getReportTicketAward(){
      vm.resultReports = true;
      var data = {
        fecha: moment(vm.date).format('DD/MM/YYYY'),
        filter: angular.copy(vm.filter),
        vendedor: vm.sellerSelected ? angular.copy(vm.sellerSelected.id) : null
      }
      $reportsService.getTicketAward(data)
      .then(function(res){
        vm.dataReport = _.reduce(res, function(memo, data){
          switch (vm.filter) {
            case 'premiados':
              if(data.premiados > 0){
                memo.push(data);
              }
              break;
          case 'pagados':
            if(data.pagados > 0){
              memo.push(data);
            }
            break;
          case 'prem/pag':
            if(data.pagados > 0 || data.pagados > 0){
              memo.push(data);
            }
          break;
          }
          return memo;
        },[]);
        vm.resultReports = false;
      })
    }

    function getTicketDetail(ticket){
      $ticketsService.getDetailTicket(ticket.id, 2)
      .then(function(res){
        vm.detailTicket = res;
        vm.detailTicket.ticket.replace(/\n|\r/g, '<br>');
        var modal = $uibModal.open({
          animation: true,
          component: 'ticketViewer',
          size: 'xs',
          resolve: {
            ticket: function() {
              return {
                agencia: ticket.nombre_agencia,
                ticket_id: ticket.ticket_id,
                detalle: vm.detailTicket.ticket
              }
            }
          }
        });
      })
    }

    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }


})();
