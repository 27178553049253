(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadAdminLte', loadAdminLte);

  loadAdminLte.$inject = ['$state','$timeout'];

  function loadAdminLte($state, $timeout) {


    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      $(el).ready(function(){
        window.loadAdminLTE();
      });
    }
  }
})();

(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadAccounting', loadAccounting);

  loadAccounting.$inject = ['$state'];

  function loadAccounting($state) {


    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      setTimeout(function(){
        window.loadAccounting();
      },0)
    }
  }
})();
