(function(){
  'use strict';

  angular
  .module('app')
  .component('editAccounts', {
    templateUrl: 'app/components/accounts-movil-pay/edit-account.html',
    controller: ModalCreateCounpon,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateCounpon.$inject = ['toastr','accountService','_','$rootScope','user','miscelaneos'];

  function ModalCreateCounpon(toastr, $accountService, _, $rootScope,$user, $miscelaneos) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.cancel = cancel;
    vm.user = $user.getCurrentUser();
    vm.setType = setType;
    vm.setBanco = setBanco;

    vm.$onInit = function(){
      vm.bancos = $miscelaneos.bancos();
      vm.typeBanco = [{tipo:'Corriente'},{tipo: 'Ahorro'}];
      vm.mobile = vm.resolve.mobile;
      vm.mobile.tipoSelected = _.findWhere(vm.typeBanco, {tipo: vm.mobile.tipo});
      vm.mobile.bancoSelected = _.findWhere(vm.bancos, {banco: vm.mobile.banco});
    }

    function save() {
      vm.send = true;

      $accountService.updatedddAccount(vm.mobile.id, vm.mobile)
      .then(function(res){
        vm.send = false;
        toastr.success('Actualizado con exito');
        $rootScope.$emit('accounts',{});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })
    }

    function setBanco(account){
      vm.mobile.banco = vm.mobile.bancoSelected.banco;
      vm.mobile.banco_id = vm.mobile.bancoSelected.codigo;
      vm.mobile.cuenta = vm.mobile.bancoSelected.codigo;
    }
    function setType(account){
      vm.mobile.tipo = vm.mobile.tipoSelected.tipo;
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
