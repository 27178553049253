(function(){
  'use strict';

  angular
  .module('app')
  .component('viewUsed', {
    templateUrl: 'app/components/coupons/history.html',
    controller: ModalHistoryController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalHistoryController.$inject = ['toastr','couponsService','_','$rootScope','user'];

  function ModalHistoryController(toastr, $couponsService, _, $rootScope,$user) {
    var vm = this;
    vm.send = false;
    vm.cancel = cancel;
    vm.user = $user.getCurrentUser();
    vm.$onInit = function(){
      vm.coupon = vm.resolve.coupon;
    }

    
    function cancel() {
        vm.dismiss();
    }
  }
})();
