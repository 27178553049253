(function(){
  'use strict';

  angular
  .module('app')
  .component('createAccounts', {
    templateUrl: 'app/components/accounts-movil-pay/admin-account.html',
    controller: ModalCreateCounpon,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateCounpon.$inject = ['toastr','accountService','_','$rootScope','user','miscelaneos'];

  function ModalCreateCounpon(toastr, $accountService, _, $rootScope,$user, $miscelaneos) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.cancel = cancel;
    vm.user = $user.getCurrentUser();
    vm.bancos = $miscelaneos.bancos();
    vm.setType = setType;
    vm.addAccount = addAccount;
    vm.setBanco = setBanco;
    vm.typeBanco = [{tipo:'Corriente'},{tipo: 'Ahorro'}];
    vm.accounts = [{
      estructura_id: vm.user.estructura.id,
      banco_id: '',
      banco: '',
      cuenta: '',
      tipo: '',
      nombre: '',
      cedula: '',
      correo: '',
      celular: ''
    }];


    vm.$onInit = function(){

    }

    function save() {
      vm.send = true;

      $accountService.createdddAccount(vm.accounts)
      .then(function(res){
        vm.send = false;
        toastr.success('Actualizado con exito');
        $rootScope.$emit('accounts',{});
        // vm.close({$value: res.data});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })
    }

    function addAccount(){
      vm.accounts.push({
        estructura_id: vm.user.estructura.id,
        banco_id: '',
        banco: '',
        cuenta: '',
        tipo: '',
        nombre: '',
        cedula: '',
        correo: '',
        celular: ''
      })
    }

    function setBanco(account){
      account.banco = account.bancoSelected.banco;
      account.banco_id = account.bancoSelected.codigo;
    }
    function setType(account){
      account.tipo = account.tipoSelected.tipo;
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
