(function(){
  'use strict';

  angular
    .module('app')
    .component('home', {
      templateUrl: 'app/components/home/home.html',
      controller: HomeController
    });

  HomeController.$inject = []

  function HomeController() {
    var vm = this

    vm.$onInit = function(){

    }
  }

})();
