(function () {
  'use strict';
  angular
  .module('app')
  .factory('permissionsService', permissionsService);

  permissionsService.$inject = ['$http', 'url'];

  function permissionsService($http, $url) {

    function getModules(){
      return $http.get($url.getApiUrl('/sections'))
      .then(function(res){
        return res.data;
      })
    }

    function getUsers(){
      return $http.get($url.getApiUrl('/usuarios'))
      .then(function(res){
        return res.data;
      })
    }





    return {
      getModules: getModules,
      getUsers: getUsers
    };
  }
})();
