(function(){
  'use strict';
  angular
  .module('app')
  .component('reportsQuintico', {
    templateUrl: 'app/components/report-quintico/reports-quintico.html',
    controller: ReportQuinticoController
  });

  ReportQuinticoController.$inject = ['toastr','$window','reportsService','structureService','miscelaneos','moment','user','$rootScope'];

  function ReportQuinticoController(toastr, $window, $reportsService, $structureService, $miscelaneos, moment, user,$rootScope) {
    var vm = this;
    vm.send = false;
    vm.point_sales = [];
    vm.getBack = getBack;
    vm.printReport = false;
    vm.clearGrupo = clearGrupo;
    vm.consultSales = consultSales;
    vm.user = user.getCurrentUser();
    vm.clearPointSale = clearPointSale;
    vm.printReportQuintico = printReportQuintico;
    vm.step = {
      step1: true,
      step2: false,
    }
    vm.$onInit = function(){
      $structureService.getStructuresChildrens()
      .then(function(data){
        vm.structures = data;
      })
      $structureService.getSellers()
      .then(function(res){
        vm.sellers = res;
      })

      $miscelaneos.getComercializador(vm.user.estructura_id)
      .then(function(res){
        vm.comercializador = res;
      })

    }

    function consultSales(){
      if(!vm.structure){
        toastr.warning('Seleccione un grupo para ver el reporte de ventas');
        return
      }
      vm.send = true;
      vm.report = [];
      vm.point_sales = [];


      var data = {
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD'),
      };

      if(vm.point_sale){
        vm.point_sales.push({
          structure_id: vm.point_sale.id,
          point_sale_id: angular.fromJson(vm.point_sale.detalle_quintico).point_sale.point_sale_id,
          sales: 0,
          awards: 0
        })
      }else{
        vm.point_sales = _.reduce(vm.structure.point_sales_structures, function(memo,data){
          if(angular.fromJson(data.detalle_quintico)){
            memo.push({
              structure_id: angular.fromJson(data.detalle_quintico).point_sale.estructura_id,
              point_sale_id: angular.fromJson(data.detalle_quintico).point_sale.point_sale_id,
              sales: 0,
              awards: 0
            });
          }

          return memo;
        }, []);
      }

      data.point_sales = vm.point_sales;
      $reportsService.getReportSalesQuintico(data)
      .then(function(res){
        vm.send = false;
        var respon = _.reject(res, function(data){
          return data.sales == 0;
        })
        vm.report = [];
        vm.report = _.map(respon, function(data){
          data.structure = _.findWhere(vm.sellers, {id: data.structure_id})
          var configuracion_estructura = _.findWhere(data.structure.configuracion_estructura, {loteria_id: 4});
          data.comision = parseFloat(configuracion_estructura.comision);
          data.participacion = parseFloat(configuracion_estructura.participacion);
          data.porcentaje_premio = (data.awards / data.sales) * 100;
          data.porcentaje_utilidad = (data.sales - data.awards) * 100;
          data.total_comision = data.sales * data.comision / 100;
          data.utilidad = data.sales - data.total_comision - data.awards;
          return data;
        });

        vm.reportResult = angular.copy(vm.report)
        vm.total = _.reduce(vm.reportResult, function(memo,data){
          memo.total_awards += parseFloat(data.awards);
          memo.total_sales += parseFloat(data.sales);
          memo.total_comision += parseFloat(data.total_comision);
          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_premio_porcentaje += parseFloat(data.porcentaje_premio);
          return memo;
        }, {total_awards: 0, total_sales: 0, total_comision: 0, total_utilidad: 0, total_premio_porcentaje: 0})
      });



    }

    function clearPointSale(){
      vm.point_sale = undefined;
    }
    function clearGrupo(){
      vm.structure = undefined;
    }

    function getBack(step){
      if(step == 'step1'){
        vm.step.step1 = true;
        vm.step.step2 = false;
        vm.reportResult = undefined;
        vm.total = undefined;
        return
      }
    }

    function printReportQuintico(typeReport){
      $rootScope.$emit('printReportQuinticoPDF', {
        dataTable: vm.reportResult,
        dataTotal: vm.total, dataCabecera:{
          desde: moment(vm.from).format('MM/DD/YYYY'),
          hasta: moment(vm.to).format('MM/DD/YYYY'),
          estructura1: vm.structure || undefined,
          estructura2: vm.point_sale || undefined
        }
      })
      vm.printReport = true;

    }

    $rootScope.$on('goBack',function(){
      vm.printReport = false;
    })














    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup.opened = true;
    };
    vm.open1 = function(){
      vm.popup.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }


})();
