(function(){
  'use strict';
  angular
  .module('app')
  .component('reportQuinticoPrint', {
    templateUrl: 'app/components/report-quintico/report-quintico-print.html',
    controller: ReportQuinticoPrintController
  });

  ReportQuinticoPrintController.$inject = ['$rootScope','$window','$timeout'];

  function ReportQuinticoPrintController($rootScope, $window, $timeout) {
    var vm = this;

    $rootScope.$on('printReportQuinticoPDF',function(evt, data){
      console.log(data)
      vm.dataTable = data.dataTable;
      vm.dataTotal = data.dataTotal;
      vm.dataCabecera = data.dataCabecera;
      $timeout(function(){
        $window.print();
      },1000)
    })

    vm.goBack = function(){
      $rootScope.$emit('goBack', {});
    }

    vm.print = function(){
      $window.print();
    }

  }

})();
