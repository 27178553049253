(function(){
  'use strict';
  angular
  .module('app')
  .component('reports', {
    templateUrl: 'app/components/reports/reports.html',
    controller: ReportController
  });

  ReportController.$inject = ['toastr','$window','reportsService','structureService','miscelaneos','moment','user','$rootScope'];

  function ReportController(toastr, $window, $reportsService, $structureService, $miscelaneos, moment, user, $rootScope) {
    var vm = this;
    vm.send = false;
    vm.printReportWindow = false;
    vm.getReport = getReport;
    vm.printReport = printReport;
    vm.user = user.getCurrentUser();
    vm.showSalesGroups = showSalesGroups;
    vm.showSalesPointSale = showSalesPointSale;
    vm.showSalesPointSaleDraw = showSalesPointSaleDraw;
    vm.getBack = getBack;
    vm.step = {
      step1: true,
      step2: false,
      step3: false,
      step4: false
    }


    vm.$onInit = function(){
      $miscelaneos.getTypeStructures()
      .then(function(res){
        vm.typestructures = res;
        vm.typeStructureSelected = _.findWhere(vm.typestructures, {nombre: 'Grupo'});
        getReport();
      })
    }

    function getReport(){
      vm.send = true;
      var data = {
        tipo_estructura_id: vm.typeStructureSelected.id,
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD')
      }
      if(data.tipo_estructura_id == 3){
        data.banca = vm.user.estructura_id
      }

      $reportsService.getReportTypeStructure(data)
      .then(function(res){
        if(vm.typeStructureSelected.id == 3){
          console.log(res)
          vm.step.step1 = false;
          vm.step.step2 = true;

          vm.resultReportPointSales = _.map(res, function(data){
            data.porcentaje_premio = (data.premio / data.venta) * 100;
            data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
            return data;
          });

          vm.total_point_sale = _.reduce(vm.resultReportPointSales, function(memo,data){
            memo.total_venta += parseFloat(data.venta);
            memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, 'Bs. ','2','.',',');
            memo.total_comision += parseFloat(data.comision);
            memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, 'Bs. ','2','.',',');
            memo.tota_premio += parseFloat(data.premio);
            memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, 'Bs. ','2','.',',');
            memo.total_utilidad += parseFloat(data.utilidad);
            memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, 'Bs. ','2','.',',');
            memo.total_utilidad_final += parseFloat(data.utilidad_final);
            memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, 'Bs. ','2','.',',');
            memo.total_participacion += parseFloat(data.participacion);
            memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

            return memo;
          },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
          vm.send = false;
        }else{
          vm.resultReports = _.map(res, function(data){
            data.porcentaje_premio = (data.premio / data.venta) * 100;
            data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
            return data;
          });

          vm.total = _.reduce(vm.resultReports, function(memo,data){
            memo.total_venta += parseFloat(data.venta);
            memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, '','2','.',',');
            memo.total_comision += parseFloat(data.comision);
            memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, '','2','.',',');
            memo.tota_premio += parseFloat(data.premio);
            memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, '','2','.',',');
            memo.total_utilidad += parseFloat(data.utilidad);
            memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, '','2','.',',');
            memo.total_utilidad_final += parseFloat(data.utilidad_final);
            memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, '','2','.',',');
            memo.total_participacion += parseFloat(data.participacion);
            memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

            return memo;
          },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
          vm.send = false;
        }
      });

    }

    function showSalesGroups(currentGrupo){
      vm.send = true;
      vm.currentGrupo = currentGrupo;
      vm.step.step1 = false;
      vm.step.step2 = true;
      var data = {
        tipo_estructura_id: vm.typeStructureSelected.id,
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD'),
        estructura_id: vm.currentGrupo.estructura_id
      }
      $reportsService.getReportSalesGroup(data)
      .then(function(res){
        vm.resultReportPointSales = _.map(res, function(data){
          data.porcentaje_premio = (data.premio / data.venta) * 100;
          data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
          return data;
        });

        vm.total_point_sale = _.reduce(vm.resultReportPointSales, function(memo,data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, 'Bs. ','2','.',',');
          memo.total_comision += parseFloat(data.comision);
          memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, 'Bs. ','2','.',',');
          memo.tota_premio += parseFloat(data.premio);
          memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, 'Bs. ','2','.',',');
          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, 'Bs. ','2','.',',');
          memo.total_utilidad_final += parseFloat(data.utilidad_final);
          memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, 'Bs. ','2','.',',');
          memo.total_participacion += parseFloat(data.participacion);
          memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

          return memo;
        },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
        vm.send = false;
      });


    }

    function showSalesPointSale(data){
      vm.step.step2 = false;
      vm.step.step3 = true;
      vm.currentPointSale = {nombre: data.nombre, estructura_id: data.estructura_id};
      var data = {
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD'),
        id: data.estructura_id
      }
      $reportsService.getReportSalesPointSalesLotery(data)
      .then(function(res){
        vm.resultReportPointSalesLoteries = _.map(res, function(data){
          data.porcentaje_premio = (data.premio / data.venta) * 100;
          data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
          return data;
        });

        vm.total_lotery = _.reduce(vm.resultReportPointSalesLoteries, function(memo,data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, 'Bs. ','2','.',',');
          memo.total_comision += parseFloat(data.comision);
          memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, 'Bs. ','2','.',',');
          memo.tota_premio += parseFloat(data.premio);
          memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, 'Bs. ','2','.',',');
          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, 'Bs. ','2','.',',');
          memo.total_utilidad_final += parseFloat(data.utilidad_final);
          memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, 'Bs. ','2','.',',');
          memo.total_participacion += parseFloat(data.participacion);
          memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

          return memo;
        },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
        vm.send = false;
      });
    }

    function showSalesPointSaleDraw(data){
      vm.step.step3 = false;
      vm.step.step4 = true;

      var data = {
        desde: moment(vm.from).format('YYYY-MM-DD'),
        hasta: moment(vm.to).format('YYYY-MM-DD'),
        id: vm.currentPointSale.estructura_id,
        loteria_id: data.loteria_id
      }
      $reportsService.getReportSalesPointSalesDraw(data)
      .then(function(res){
        vm.resultReportPointSalesDraws = _.map(res, function(data){
          data.porcentaje_premio = (data.premio / data.venta) * 100;
          data.porcentaje_utilidad = (data.utilidad_final / data.venta) * 100;
          return data;
        });

        vm.total_lotery_draw = _.reduce(vm.resultReportPointSalesDraws, function(memo,data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_venta_pretty = accounting.formatMoney(memo.total_venta, 'Bs. ','2','.',',');
          memo.total_comision += parseFloat(data.comision);
          memo.total_comision_pretty = accounting.formatMoney(memo.total_comision, 'Bs. ','2','.',',');
          memo.tota_premio += parseFloat(data.premio);
          memo.tota_premio_pretty = accounting.formatMoney(memo.tota_premio, 'Bs. ','2','.',',');
          memo.total_utilidad += parseFloat(data.utilidad);
          memo.total_utilidad_pretty = accounting.formatMoney(memo.total_utilidad, 'Bs. ','2','.',',');
          memo.total_utilidad_final += parseFloat(data.utilidad_final);
          memo.total_utilidad_final_pretty = accounting.formatMoney(memo.total_utilidad_final, 'Bs. ','2','.',',');
          memo.total_participacion += parseFloat(data.participacion);
          memo.total_participacion_pretty = accounting.formatMoney(memo.total_participacion, '','2','.',',');

          return memo;
        },{total_venta: 0, total_venta_pertty: '', total_comision: 0, total_comision_pretty: '', tota_premio: 0, tota_premio_pretty: '', total_utilidad:0, total_utilidad_pretty: '', total_utilidad_final: 0, total_utilidad_final_pretty: '', total_participacion:0, total_participacion_pretty:''});
        vm.send = false;
      });
    }

    function getBack(step){
      if(step == 'step3'){
        vm.step.step4 = false;
        vm.step.step3 = true;
        return
      }
      if(step == 'step2'){
        vm.step.step3 = false;
        vm.step.step2 = true;
        return
      }
      if(step == 'step1'){
        vm.step.step1 = true;
        vm.step.step2 = false;
        return
      }
    }


    function printReport(typeReport){
      if(typeReport == 'grupo'){
        $rootScope.$emit('printReportStructurePDF', {
          dataTable: vm.resultReports,
          dataTotal: vm.total, dataCabecera:{
            desde: moment(vm.from).format('MM/DD/YYYY'),
            hasta: moment(vm.to).format('MM/DD/YYYY'),
            estructura1: {nombre: 'Todos los grupos'} || undefined,
          }
        })
      }
      if(typeReport == 'point_sales'){
        $rootScope.$emit('printReportStructurePDF', {
          dataTable: vm.resultReportPointSales,
          dataTotal: vm.total_point_sale, dataCabecera:{
            desde: moment(vm.from).format('MM/DD/YYYY'),
            hasta: moment(vm.to).format('MM/DD/YYYY'),
            estructura1: vm.currentGrupo || undefined,
          }
        })
      }
      if(typeReport == 'point_sales_lotery'){
        $rootScope.$emit('printReportStructurePDF', {
          dataTable: vm.resultReportPointSalesLoteries,
          dataTotal: vm.total_lotery, dataCabecera:{
            desde: moment(vm.from).format('MM/DD/YYYY'),
            hasta: moment(vm.to).format('MM/DD/YYYY'),
            estructura1: vm.currentGrupo || undefined,
            estructura2: vm.currentPointSale || undefined
          }
        })
      }
      if(typeReport == 'point_sales_lotery_draw'){
        $rootScope.$emit('printReportStructurePDF', {
          dataTable: vm.resultReportPointSalesDraws,
          dataTotal: vm.total_lotery_draw, dataCabecera:{
            desde: moment(vm.from).format('MM/DD/YYYY'),
            hasta: moment(vm.to).format('MM/DD/YYYY'),
            estructura1: vm.currentGrupo || undefined,
            estructura2: vm.currentPointSale || undefined
          }
        })
      }
      vm.printReportWindow = true;

    }

    $rootScope.$on('goBack',function(){
      vm.printReportWindow = false;
    })






    //Input date
    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }


})();
