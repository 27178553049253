(function () {
  'use strict';
  angular
  .module('app')
  .factory('ticketsService', ticketsService);

  ticketsService.$inject = ['$http', 'url'];

  function ticketsService($http, $url) {

    function hashToParams(params){
      var params_url = '';

      Object.keys(params).forEach(function(item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    function getTicketsFromSellers(data){
      return $http.get($url.getApiUrl('/tickets/obtener_tickets?fecha='+data.date+'&vendedor_id='+data.id+'&tipo='+data.type+'&status='+data.status))
      .then(function(res){
        return res.data;
      })
    }

    function getDetailTicket(data) {
      return $http.get($url.getApiUrl('/tickets/obtener_detalle_ticket'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function deleteTickets(data){
      return $http.post($url.getApiCentinelaUrl('/ventas/borrar_ticket_banca'), data)
      .then(function(res){
        return res.data;
      })
    }





    return {
      getTicketsFromSellers: getTicketsFromSellers,
      getDetailTicket: getDetailTicket,
      deleteTickets: deleteTickets,


    };
  }
})();
