(function(){
  'use strict';

  angular
  .module('app')
  .component('createUserComponent', {
    templateUrl: 'app/components/structure/createUser.html',
    controller: ModalCreatUserController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreatUserController.$inject = ['toastr','authService','$rootScope'];

  function ModalCreatUserController(toastr, $authService, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    setTimeout(function(){
      vm.structure = vm.resolve.structure;
      console.log(vm.structure)
    },0)

    function save() {
      vm.send = true;
      vm.user.estructura_id = vm.structure.id;
      vm.user.estructura_nombre = vm.structure.nombre;
      $authService.createUser(vm.user)
      .then(function(res){
        console.log(res);
        vm.send = false;
        toastr.info('Usuario Creado con exito');
        vm.close({$value: res});
        $rootScope.$emit('structures',{});
      },function(err){
        vm.send = false;
        toastr.error(err.data.message);
      });
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
