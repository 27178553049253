(function() {
  'use strict';
  angular
    .module('app')
    .directive('estructuraCheck', estructuraCheck);

  estructuraCheck.$inject = ['$http', '$q', 'url', '$rootScope'];

  function estructuraCheck($http, $q, url, $rootScope) {
    return {
      require: 'ngModel',
      scope: {
        estructuraCheck: '=',
      },
      link: link,
    };

    function link(scope, el, attrs, ctrl) {
      ctrl.$asyncValidators.estructuraCheck = function(modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty model valid
          return $q.when();
        }
        var defer = $q.defer();
        var data = { nombre: modelValue };
        if(scope.estructuraCheck) {
          data.id = scope.estructuraCheck;
        }
        $http
          .post(url.getApiUrl('/miscelaneos/check_nombre_estructura'), data)
          .then(function(res) {
            if (res.data.exist) {
              $rootScope.$broadcast('estructuraCheck', {exist: true, message: 'El Nombre de estructura ya esta siendo usado', text: 'Cambie el nombre de la estructura para continuar'});
              defer.reject();
            }else{
              $rootScope.$broadcast('estructuraCheckTrue');
              defer.resolve();
            }
          });
        return defer.promise;
      };
    }
  }
})();
