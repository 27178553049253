(function () {
  'use strict';

  angular
  .module('app')
  .factory('accountService', accountService);

  accountService.$inject = ['$http', 'url'];

  function accountService($http, $url) {

    function getAccounts(){
      return $http.get($url.getApiUrl('/banco'))
      .then(function(res){
        return res.data;
      })
    }

    function createdddAccount(data){
      return $http.post($url.getApiUrl('/banco'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updatedddAccount(id, data){
      return $http.put($url.getApiUrl('/banco/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function deletedddAccount(id){
      return $http.delete($url.getApiUrl('/banco/'+id))
      .then(function(res){
        return res.data;
      })
    }

    return {
      getAccounts: getAccounts,
      createdddAccount: createdddAccount,
      deletedddAccount: deletedddAccount,
      updatedddAccount: updatedddAccount
    };
  }
})();
