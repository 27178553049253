angular
  .module('app', [
    'ui.router',
    'slick',
    'ngAnimate',
    'ngSanitize',
    'toastr',
    'ui.bootstrap',
    'angular-ladda',
    'ui.select',
    'angularBootstrapNavTree',
    'ui.checkbox',
    'ja.qr',
    'ngActionCable',
    'ngAudio'
  ]);
