(function(){
  'use strict';

  angular
    .module('app')
    .component('technicians', {
      templateUrl: 'app/components/technicians/technicians.html',
      controller: UsersController
    });

    UsersController.$inject = ['tecnicianService','$rootScope','$uibModal', 'toastr'];

  function UsersController($tecnicianService, $rootScope, $uibModal, toastr) {
    var vm = this;
    vm.createTechnicians = createTechnicians;
    vm.destroy = destroy;
    vm.edit = edit;

    function init(){
      $tecnicianService.getTechnicians()
      .then(function(res){
        vm.technicians = res;
      })
    }
    init();

    function createTechnicians(){
      var modal = $uibModal.open({
        animation: true,
        component: 'createTechnicians',
        size: 'lg',
        resolve: {
          sections: function(){
            return angular.copy(vm.sections)
          }
        }
      });

      modal.result.then(function(res){
        vm.technicians.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function edit(user) {
      var modal = $uibModal.open({
        animation: true,
        component: 'editTechnician',
        size: 'lg',
        resolve: {
          user: function(){
            return user
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function destroy(user, index) {
      swal({
        title: 'Confirmar borrado',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $tecnicianService.deleteTechnician(user.id)
            .then(function() {
              vm.technicians.splice(index, 1)
              resolve();
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function() {
        swal(
          'Borrado!',
          'Ha sido borrado con éxito.',
          'success'
        );
      });
    }



    $rootScope.$on('technicians', function(event, data){
      init();
    })



    $rootScope.$emit('webLayout', {data: {title: 'Usuarios',subtitle: 'Administracion de usuarios', previewPage: 'Dashboard'}});

  };

})();
