(function () {
  'use strict';
  angular
  .module('app')
  .factory('tecnicianService', tecnicianService);

  tecnicianService.$inject = ['$http', 'url'];

  function tecnicianService($http, $url) {

    function getTechnicians(){
      return $http.get($url.getApiUrl('/tecnicos'))
      .then(function(res){
        return res.data;
      })
    }

    function createTechnician(data){
      return $http.post($url.getApiUrl('/tecnicos'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateTechnician(id, data){
      return $http.put($url.getApiUrl('/tecnicos/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function deleteTechnician(id){
      return $http.delete($url.getApiUrl('/tecnicos/'+id))
      .then(function(res){
        return res.data;
      })
    }

    return {
      getTechnicians: getTechnicians,
      createTechnician: createTechnician,
      deleteTechnician: deleteTechnician,
      updateTechnician: updateTechnician
    };
  }
})();
