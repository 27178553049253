(function(){
  'use strict';

  angular
  .module('app')
  .component('editTechnician', {
    templateUrl: 'app/components/technicians/createTechnicians.html',
    controller: ModalEditTechnician,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalEditTechnician.$inject = ['toastr','tecnicianService','_','$rootScope'];

  function ModalEditTechnician(toastr, $tecnicianService, _, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.activeTap = 0;

    setTimeout(function(){
      vm.user = vm.resolve.user;
    },0);



    function save() {
      vm.send = true;
      $tecnicianService.updateTechnician(vm.user.id, vm.user)
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
        vm.close({$value: res.data});
      }, function(err){
        toastr.error('Hubo un error');
      })
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
