(function(){
  'use strict';

  angular
  .module('app')
  .component('createStructureComponent', {
    templateUrl: 'app/components/structure/createStructure.html',
    controller: ModalCreateStructureController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateStructureController.$inject = ['toastr','structureService','$rootScope','_','$window','$timeout'];

  function ModalCreateStructureController(toastr, $structureService, $rootScope,_, $window, $timeout) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.nextStep = nextStep;
    vm.backStep = backStep;
    vm.send = false;
    vm.failNameEstructuraText = '';
    vm.failValitaionUniqueData = false;
    vm.hideParticipacion = false;
    vm.changeStructureType = changeStructureType;
    vm.calculateAmount = calculateAmount;
    vm.calculatePercent = calculatePercent;
    vm.calculateAmountTriple = calculateAmountTriple;

    vm.$onInit = function(){
      vm.states = vm.resolve.states;
    }
    setTimeout(function(){
      vm.structure = vm.resolve.structure;
      vm.type_structures = vm.resolve.type_structures;
      vm.structures = vm.resolve.structures;
      vm.padre = vm.resolve.padre;
      _.forEach(vm.structure.configuracion_limites, function(data){
        _.forEach(data.sorteos, function(sorteo){
          sorteo.porcentajeReference = _.findWhere(vm.padre, {sorteo_id: sorteo.id}).limite;
          sorteo.porcentajeReferenceTriple = _.findWhere(vm.padre, {sorteo_id: sorteo.id}).limite_triple;
          calculateAmount(sorteo);
          calculateAmountTriple(sorteo);
        })
      })
      _.forEach(vm.structure.configuracion_estructura, function(data){
        data.hideParticipacion = false;
      })
      console.log(vm.structure.configuracion_estructura)
    })

    vm.steps = {
      stepOne: true,
      secondStep: false,
      thirdStep: false,
      fourStep: false
    }

    function nextStep(step){

      if(vm.steps.stepOne){
        vm.steps.stepOne = false;
        vm.steps.secondStep = true;
        return;
      }
      if(vm.steps.secondStep){
        vm.steps.secondStep = false;
        vm.steps.thirdStep = true;
        return;
      }
      if(vm.steps.thirdStep){
        vm.steps.thirdStep = false;
        vm.steps.fourStep = true;
        return;
      }

      if(vm.steps.fourStep){
        vm.steps.thirdStep = false;
        vm.steps.fourStep = true;
        return;
      }

    }

    function backStep(step){

      if(vm.steps.secondStep){
        vm.steps.stepOne = true;
        vm.steps.secondStep = false;
        return;
      }

      if(vm.steps.thirdStep){
        vm.steps.secondStep = true;
        vm.steps.thirdStep = false;
        return;
      }

      if(vm.steps.fourStep){
        vm.steps.thirdStep = true;
        vm.steps.fourStep = false;
        return;
      }

    }

    function save() {
      vm.send = true;
      var limites = [];

      _.forEach(vm.structure.configuracion_limites, function(loteria){
        _.forEach(loteria.sorteos, function(sorteo){
          limites.push({
            sorteo_id: sorteo.id,
            porcentaje: sorteo.porcentaje,
            limite: sorteo.limite,
            limite_triple: sorteo.limite_triple,
            porcentaje_triple: sorteo.porcentaje_triple
          })
        })
      })

      delete vm.structure.configuracion_limites;
      vm.structure.limites = limites;
      if(vm.structure.tipo_estructura_id == 3){
        vm.structure.estado = vm.estadoSelected.id;
        vm.structure.municipio = vm.municipioSelected.id;
      }
      $structureService.saveStructure(vm.structure)
      .then(function(res){
        $window.swal('Exito',res.message,'success');
        vm.close({$value: res.data});
        $rootScope.$emit('structures', {});
      }, function(err){
        vm.send = false;
        $window.swal('Error',err.data.message,'error');
      })

    }

    function calculatePercent(data){
      data.porcentaje = data.limite / data.porcentajeReference * 100;
      setAmountPretty(data)
    }

    function calculateAmount(data){
      data.limite = data.porcentajeReference * data.porcentaje / 100;
      setAmountPretty(data)
    }

    function setAmountPretty(data){
      data.pretty_amount = accounting.formatMoney(data.limite, 'Bs. ','2','.',',');
    }

    function calculatePropina(conf){
      var monto = conf.comision_premio_mayor * conf.amount_reference / 100;
      conf.monto_participacion = accounting.formatMoney(monto, '','2','.',',');
    }

    //triple
    function calculateAmountTriple(data){
      data.limite_triple = data.porcentajeReferenceTriple * data.porcentaje_triple / 100;
      setAmountPrettyTriple(data)
    }

    function setAmountPrettyTriple(data){
      data.pretty_amount_triple = accounting.formatMoney(data.limite_triple, 'Bs. ','2','.',',')
    }

    function setAmountPrettyTriple(data){
      data.pretty_amount_triple = accounting.formatMoney(data.limite_triple, 'Bs. ','2','.',',')
    }

    function cancel() {
        vm.dismiss();
    }

    function changeStructureType(data){
      if(parseInt(data) === 3){
        $timeout(function(){
          _.forEach(vm.structure.configuracion_estructura, function(data){
            data.hideParticipacion = true;
          })
        },0)
      }else{
        $timeout(function(){
        _.forEach(vm.structure.configuracion_estructura, function(data){
          data.hideParticipacion = false;
        })
        },0)
      }
    }

    $rootScope.$on('estructuraCheck', function(evt, data){
      if(data.exist){
        $window.swal('Error',data.message,'error');
        vm.failValitaionUniqueData = true;
        vm.failNameEstructuraText = data.text;
      }
    });
    $rootScope.$on('estructuraCheckTrue', function(evt, data){
      vm.failValitaionUniqueData = false;
    });




  }
})();
