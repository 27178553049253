(function(){
  'use strict';
  angular
  .module('app')
  .component('reportPrint', {
    templateUrl: 'app/components/reports/report-print.html',
    controller: ReportPrintController
  });

  ReportPrintController.$inject = ['$rootScope','$window','$timeout'];

  function ReportPrintController($rootScope, $window, $timeout) {
    var vm = this;

    vm.$onInit = function(){

    }

    $rootScope.$on('printReportStructurePDF',function(evt, data){
      console.log(data)
      vm.dataTable = data.dataTable;
      vm.dataTotal = data.dataTotal;
      vm.dataCabecera = data.dataCabecera;
      $timeout(function(){
        $window.print();
      },1000)
    })

    vm.goBack = function(){
      $rootScope.$emit('goBack', {});
    }

    vm.print = function(){
      $window.print();
    }

  }

})();
