(function(){
  'use strict';

  angular
    .module('app')
    .component('permission', {
      templateUrl: 'app/components/permissions/permissions.html',
      controller: PermissionsController
    });

  PermissionsController.$inject = ['permissionsService','$rootScope'];

  function PermissionsController($permissionsService, $rootScope) {
    var vm = this;
    vm.users = [];
    vm.sections = [];
    // vm.userSelected = {};

    function init(){
      $permissionsService.getModules()
      .then(function(res){
        vm.sections = res;
      })

      $permissionsService.getUsers()
      .then(function(res){
        vm.users = res;
      })
    }
    init();
    vm.show = show;
    function show(data){
      console.log(data)
    }

    $rootScope.$on('permissions', function(event, data){
      init();
    })



    $rootScope.$emit('webLayout', {data: {title: 'Permisos',subtitle: 'Administrar permisos de usuario', previewPage: 'Dashboard'}});

  }

})();
