(function(){
  'use strict';

  angular
    .module('app')
    .component('requestReleases', {
      templateUrl: 'app/components/request-releases/request-releases.html',
      controller: RequestReleasesController
    });

  RequestReleasesController.$inject = ['structureService','$window','toastr']

  function RequestReleasesController($structureService, $window,toastr) {
    var vm = this

    vm.$onInit = function(){
      $structureService.getReleaseRequest()
      .then(function(res){
        vm.requests = res;
      })
    }

    vm.approve = function(data){


      $window.swal({
        title: '¿Esta seguro?',
        text: "",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aprobar'
      }).then(function(result){
        if (result.value) {
          var params = {
            id: data.id
          }
          $structureService.approveRelease(params)
          .then(function(res){
            vm.$onInit()
            toastr.success(res.message);
          }, function(err){
            toastr.error(err.data.message);
          })
        }
      })

    }
    vm.decline = function(data){
      $window.swal({
        title: '¿Esta seguro?',
        text: "",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Rechazar'
      }).then(function(result){
        if (result.value) {
          var params = {
            id: data.id
          }
          $structureService.rejectRelease(params)
          .then(function(res){
            vm.$onInit()
            toastr.warning(res.message);
          }, function(err){
            toastr.error(err.data.message);
          })
        }
      })
    }
  }



})();
