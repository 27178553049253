(function() {
  'use strict';
  angular
    .module('app')
    // .constant('BASE_URL', 'http://localhost:4002')
    // .constant('OPERADORA_URL', 'http://localhost:4000')
    .constant('BASE_URL', 'http://34.227.3.72:4002')
    .constant('OPERADORA_URL', 'http://34.227.3.72:4000')
    .constant('moment', moment)
    .constant('_', _)
    .constant('DOMAIN_URL', 'http://34.227.3.72:4003');
})();
