(function () {
  'use strict';
  angular
  .module('app')
  .factory('installationService', installationService);

  installationService.$inject = ['$http', 'url'];

  function installationService($http, $url) {


    function hashToParams(params){
      var params_url = '';

      Object.keys(params).forEach(function(item, index) {
        if (index == 0 && params[item] != null) {
          params_url += '?' + item + '=' + params[item];
        } else if (index != 0 && params[item] != null) {
          params_url += '&' + item + '=' + params[item];
        }
      });

      return params_url;
    }

    function getInstallations(){
      return $http.get($url.getApiUrl('/instalaciones'))
      .then(function(res){
        return res.data;
      })
    }

    function getByTechInstallations(data){
      return $http.get($url.getApiUrl('/instalaciones/instalaciones_tecnicos'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function assignInstallations(data){
      return $http.post($url.getApiUrl('/instalaciones/asignar_instalaciones'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getPointSales(data){
      return $http.get($url.getApiUrl('/estructura/obtener_vendedores'))
      .then(function(res){
        return res.data
      })
    }

    function saveSettigs(data){
      return $http.post($url.getApiUrl('/configuracion_estacion'), data)
      .then(function(res){
        return res.data;
      })
    }

    function updateSettings(id,data){
      return $http.put($url.getApiUrl('/configuracion_estacion/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    return {
      getInstallations: getInstallations,
      assignInstallations: assignInstallations,
      getByTechInstallations: getByTechInstallations,
      getPointSales: getPointSales,
      saveSettigs: saveSettigs,
      updateSettings: updateSettings
    };
  }
})();
